/* eslint-disable max-lines */
import { deleteCompaniesListAction } from 'actions/company_lists/delete-companies-list-action';
import { getSemanticSearchAction } from 'actions/company_lists/get-semantic-search-action';
import updateDiscoveryColumnResizeAction from 'actions/explore-table-ui/update-discovery-column-resize-action';
import { updateDiscoveryColumnsOrderAction } from 'actions/explore/update-discovery-columns-order-action';
import { dismissNotificationAction } from 'actions/notifications/dismiss-notification-action';
import { sendExploreEvent } from 'actions/users/send-user-event-action';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import FileDropComponent from 'components/FileDropComponent';
import LoaderComponent from 'components/LoaderComponent';
import AddColumnsFromCsvDialog from 'components/discovery/AddColumnsFromCsvDialog';
import FileDropComponentOnTable from 'components/discovery/FileDropComponentOnTable';
import DiscoveryTable from 'components/elements/table/DiscoveryTable';
import BottomBarDiscovery from 'components/tile/discovery/BottomBarDiscoveryConnector';
import { TOAST_TYPES } from 'constants/toasts';
import { USER_EVENTS } from 'constants/userEvents';
import { dispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearFilterByColumnId,
  updateExploreFilters,
  updateExploreHiddenColumns,
  updateFiltersByColumnId
} from 'reducer/explore-slice';
import {
  clearExploreTableUIState,
  markAddColumnsCsvDialog,
  updateExploreCsv
} from 'reducer/explore-table-ui';
import {
  getCompanyListHistoryByListId,
  getExploreFiltersById,
  getExploreFiltersBypassById,
  getExploreHiddenColumnsById,
  getExploreViewByIdAndViewId
} from 'selectors/explore';
import {
  getExploreUiCsvByListId,
  getIsExploreAddColumnsCsvClosed
} from 'selectors/explore-table-ui';
import { getListNewNotifications } from 'selectors/notifications';
import {
  columnsFromMergedRows,
  createOneRow,
  filterExploreRow,
  getColumnGrouping
} from 'utils/companyList-utils';
import { combineViewAndUrl } from 'utils/explore-view-utils';
import { isEmpty } from 'utils/objects-utils';

/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
function DiscoveryRunScreenTable({
  viewId,
  isLoadingRows,
  heights,
  haveSelectedCompanies,
  onDeepDive,
  onExtend,
  onNewList,
  name,
  rows,
  addToast,
  enterNewCompany,
  loadingName,
  handleFiles,
  customColumns,
  columnsSettings,
  listId,
  isDev,
  scrollElement,
  runningRows,
  featureFlags,
  highlightedColumns
}) {
  const reduxDispatch = useDispatch();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [uploadedColumns, setUploadedColumns] = useState();
  const [warnings, setWarnings] = useState([]);
  const view = useSelector((state) => getExploreViewByIdAndViewId(state, listId, viewId));
  const filtersBypass = useSelector((state) => getExploreFiltersBypassById(state, listId));
  const listCsv = useSelector((state) => getExploreUiCsvByListId(state, listId));
  const listNewNotifications = useSelector((state) => getListNewNotifications(state, listId));
  const [localListNewNotifications, setLocalListNewNotifications] = useState({});
  const isAddColumnsClosed = useSelector((state) => getIsExploreAddColumnsCsvClosed(state, listId));
  const history = useSelector((state) => getCompanyListHistoryByListId(state, listId));

  useEffect(() => {
    if (isEmpty(listNewNotifications)) return;

    setLocalListNewNotifications((prev) => {
      return { ...prev, ...listNewNotifications };
    });
    reduxDispatch(dismissNotificationAction(Object.keys(listNewNotifications)));
  }, [listNewNotifications, reduxDispatch]);

  useEffect(() => {
    if (filtersBypass) {
      Object.keys(filtersBypass).forEach((metaId) => {
        if (warnings.includes(metaId)) return;
        if (filtersBypass[metaId].warn) {
          addToast(
            `Added company "${filtersBypass[metaId].name}" does not satisfy the currently applied filters!\nHowever, it will be displayed in the table until you change the filters or refresh the page.`,
            TOAST_TYPES.WARNING,
            { horizontal: 'center', vertical: 'top' }
          );
          setWarnings((prev) => [...prev, metaId]);
        }
      });
    }
  }, [warnings, filtersBypass, setWarnings, addToast]);

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    if (view) {
      const combinedView = combineViewAndUrl(view, searchParams);
      reduxDispatch(
        updateExploreHiddenColumns({
          listId,
          columnsIds: combinedView?.hidden_columns || [],
          isHidden: true,
          reset: true
        })
      );
      reduxDispatch(
        updateExploreFilters({
          listId,
          filters: combinedView?.filters || {}
        })
      );
      if (combinedView?.filters?.description?.data) {
        const { data } = combinedView.filters.description;
        const { searchTerm, type } = data;
        if (type === 'semantic') {
          reduxDispatch(
            getSemanticSearchAction(listId, searchTerm, (scores, companiesIds) => {
              reduxDispatch(
                updateFiltersByColumnId({
                  listId,
                  columnId: 'description',
                  data: { searchTerm, scores, companiesIds, semanticThreshold: 0.8, type }
                })
              );
            })
          );
        } else {
          reduxDispatch(
            updateFiltersByColumnId({
              listId,
              columnId: 'description',
              data: { searchTerm, type }
            })
          );
        }
      } else {
        reduxDispatch(clearFilterByColumnId({ listId, columnId: 'description' }));
      }
      return;
    }

    for (const key of searchParams.keys()) {
      if (key === 'hidden_columns') {
        const hiddenColumnsIds = searchParams.getAll(key);
        reduxDispatch(
          updateExploreHiddenColumns({ listId, columnsIds: hiddenColumnsIds, isHidden: true })
        );
      }
      if (key === 'description') {
        const data = searchParams.getAll(key);
        if (data?.length > 0) {
          const [searchTerm, type] = data[0].split(',');
          if (type === 'semantic') {
            reduxDispatch(
              getSemanticSearchAction(listId, searchTerm, (scores, companiesIds) => {
                reduxDispatch(
                  updateFiltersByColumnId({
                    listId,
                    columnId: key,
                    data: { searchTerm, scores, companiesIds, semanticThreshold: 0.8, type }
                  })
                );
              })
            );
          } else {
            reduxDispatch(
              updateFiltersByColumnId({
                listId,
                columnId: key,
                data: { searchTerm, type }
              })
            );
          }
        }
      } else {
        const data = searchParams.getAll(key);
        if (data?.length > 0) {
          reduxDispatch(
            updateFiltersByColumnId({
              listId,
              columnId: key,
              data: data[0].split(',')
            })
          );
        }
      }
    }
  }, [listId, view, reduxDispatch]);

  const exploreFilters = useSelector((state) => getExploreFiltersById(state, listId));
  const hiddenColumns = useSelector((state) => getExploreHiddenColumnsById(state, listId));

  const emptyList = rows && rows?.length === 0;

  const onDeletionError = useCallback(
    (companyName) => {
      addToast(`Failed to delete ${companyName}`, TOAST_TYPES.ERROR);
    },
    [addToast]
  );

  const columns = useMemo(() => {
    const newListIterationsSet = new Set(
      Object.values(localListNewNotifications).map((notification) => notification.run_iteration)
    );

    const rowsWithHighlighted = rows?.map((row) => {
      return { ...row, highlighted: newListIterationsSet.has(row.iteration) };
    });

    return columnsFromMergedRows(
      rowsWithHighlighted,
      customColumns,
      listId,
      columnsSettings,
      isDev,
      featureFlags,
      history
    );
  }, [
    rows,
    customColumns,
    listId,
    columnsSettings,
    isDev,
    featureFlags,
    localListNewNotifications,
    history
  ]);

  useEffect(() => {
    if (!listCsv || Boolean(uploadedColumns)) return;

    const columnsNames = columns
      .filter(
        (column) => column.data_type !== 'add_column' && column.data_type !== 'bulk_company_name'
      )
      .map((column) => column.name);

    const columnsNamesSet = new Set(columnsNames);
    const newColumns = listCsv[0].slice(1).reduce((result, columnName, index) => {
      if (!columnsNamesSet.has(columnName)) {
        result.push({ index: index + 1, name: columnName });
      }
      return result;
    }, []);
    if (newColumns.length > 0) {
      setUploadedColumns(newColumns);
    } else {
      dispatch(updateExploreCsv({ listId, csv: null }));
    }
  }, [listId, columns, listCsv, uploadedColumns]);

  const columnGrouping = useMemo(() => {
    return getColumnGrouping(customColumns);
  }, [customColumns]);

  const handleDeleteRow = useCallback(
    (id) => {
      reduxDispatch(clearExploreTableUIState({ listId }));
      reduxDispatch(deleteCompaniesListAction(listId, [id], onDeletionError));
      dispatch(sendExploreEvent(USER_EVENTS.DELETED_COMPANY, listId, 'Deleted company', { id }));
    },
    [listId, onDeletionError, reduxDispatch]
  );

  const handleColumnResize = useCallback(
    (resizeData) => {
      const { colDef, width } = resizeData;
      reduxDispatch(updateDiscoveryColumnResizeAction(listId, colDef?.field, width));
    },
    [listId, reduxDispatch]
  );

  const handleColumnsOrderChange = useCallback(
    (columnsOrder) => {
      reduxDispatch(updateDiscoveryColumnsOrderAction(listId, columnsOrder));
    },
    [listId, reduxDispatch]
  );

  const actualColoumns = useMemo(() => {
    if (!columns?.[0]?.values) return;
    const newColumns = [...columns];
    newColumns[0].values = columns[0].values.map((data) => ({
      ...data,
      onDelete: handleDeleteRow
    }));
    return newColumns;
  }, [columns, handleDeleteRow]);

  const handleDeleteRows = () => {
    reduxDispatch(deleteCompaniesListAction(listId, null, onDeletionError));
    dispatch(sendExploreEvent(USER_EVENTS.DELETED_COMPANIES, listId, 'Deleted multiple companies'));
  };

  const createEmptyRow = (id, colId) => {
    const row = createOneRow(
      id,
      loadingName,
      emptyList ? null : handleFiles,
      enterNewCompany,
      colId
    );
    return row;
  };

  const handleCloseAddColumnsFromCsvDialog = () => {
    setUploadedColumns([]);
    reduxDispatch(markAddColumnsCsvDialog({ listId, isClosed: true }));
  };

  return (
    <React.Fragment>
      {actualColoumns && actualColoumns.length ? (
        <React.Fragment>
          <FileDropComponentOnTable
            isFullScreen={isFullScreen}
            handleFiles={handleFiles}
            open={!emptyList}>
            <DiscoveryTable
              listId={listId}
              viewId={viewId}
              columns={actualColoumns}
              columnGrouping={columnGrouping}
              hiddenColumns={hiddenColumns}
              title={name}
              elementId="DiscoveryTable"
              createEmptyRow={createEmptyRow}
              isLoadingRows={isLoadingRows}
              tableHeaderHeight={heights.tableHeaderHeight}
              setIsFullScreen={setIsFullScreen}
              onColumnResize={handleColumnResize}
              onColumnsOrderChange={handleColumnsOrderChange}
              filterRow={(row) =>
                filterExploreRow(row, exploreFilters, isDev, filtersBypass, reduxDispatch)
              }
              scrollElement={scrollElement}
              runningRows={runningRows}
              toolbarArgs={{
                companyListId: listId,
                onNewList
              }}
              highlightedColumns={highlightedColumns}
            />
            {haveSelectedCompanies && (
              <BottomBarDiscovery
                listId={listId}
                onRowsDeleted={handleDeleteRows}
                onNewList={onNewList}
                onDeepDive={onDeepDive}
                onExtend={onExtend}
              />
            )}
          </FileDropComponentOnTable>
          {emptyList && <FileDropComponent handleFiles={handleFiles} showTemplate />}
        </React.Fragment>
      ) : (
        <LoaderComponent></LoaderComponent>
      )}
      <AddColumnsFromCsvDialog
        listId={listId}
        open={uploadedColumns?.length > 0 && !isAddColumnsClosed}
        newColumns={uploadedColumns}
        onClose={handleCloseAddColumnsFromCsvDialog}
      />
    </React.Fragment>
  );
}

DiscoveryRunScreenTable.propTypes = {
  discoveryId: PropTypes.string,
  viewId: PropTypes.string,
  isLoadingRows: PropTypes.bool,
  heights: PropTypes.object,
  haveSelectedCompanies: PropTypes.bool,
  onDeepDive: PropTypes.func,
  onExtend: PropTypes.func,
  onNewList: PropTypes.func,
  onStartCustomAnalytics: PropTypes.func,
  name: PropTypes.string,
  rows: PropTypes.array,
  addToast: PropTypes.func,
  enterNewCompany: PropTypes.func,
  loadingName: PropTypes.string,
  handleFiles: PropTypes.func,
  customColumns: PropTypes.object,
  columnsSettings: PropTypes.object,
  listId: PropTypes.number,
  isDev: PropTypes.bool,
  scrollElement: PropTypes.object,
  runningRows: PropTypes.array,
  featureFlags: PropTypes.array,
  highlightedColumns: PropTypes.array
};

export default wrapWithError(DiscoveryRunScreenTable);
