import React from 'react';

function AnswerIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 12L3 14C3 15.6569 4.34314 17 6 17L8 17L8 21L13 17L17 17C18.6569 17 20 15.6569 20 14L20 9L20 7C20 5.34315 18.6569 4 17 4L12.6429 4L6 4C4.34315 4 3 5.34314 3 7L3 12Z"
        stroke="#584DA5"
        strokeLinecap="round"
        strokeLinejoin="round"></path>
      <path d="M7 9H16" stroke="#584DA5" strokeLinecap="round"></path>
      <path d="M7 12H12" stroke="#584DA5" strokeLinecap="round"></path>
    </svg>
  );
}

export default AnswerIcon;
