import { addPostQueryToThread, getThreadKey } from 'reducer/custom-analytics';

export function loadDiscoveryCustomAnalyticsQuestionsHistoryAction(listId, onLoaded) {
  return (dispatch, _getState, apiClient) => {
    apiClient.companiesLists
      .fetchListRecordsHistory(listId, 9, 'custom_analytics')
      .then((res) => {
        if (res.ok) {
          const { data } = res;
          const threadKey = `${getThreadKey(listId)}-history`;
          data?.records
            ?.filter((record) => record.status === 'SUCCEEDED')
            ?.forEach((record) => {
              const postId = new Date(record.time_submitted).getTime();
              dispatch(
                addPostQueryToThread({
                  threadKey,
                  postId,
                  query: record.parameters?.question,
                  body: {
                    answerType: record.parameters?.answer_type,
                    model: record.parameters?.model,
                    createdAt: record.time_submitted,
                    isHistory: true
                  }
                })
              );
            });
        }
        onLoaded?.();
      })
      .catch(() => onLoaded?.());
  };
}
