import { translateMetaRecordForState } from 'actions/explore/load-all-explores-action';
import { getReactState } from 'actions/get-react-state-action';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import {
  COMPANY_EXTRA_DATA_TYPES,
  DATA_TYPE_TO_FEATURE_FLAG,
  OVERRIDE_EXTRA_DATA_COLUNNS,
  OVERRIDE_STATUS_FIELD
} from 'constants/company-extra-data';
import { USER_EVENTS } from 'constants/userEvents';
import { updateColumnDataLoading, updateListPartialColumnData } from 'reducer/explore-slice';
import { getListCustomColumnsByListId } from 'selectors/explore';
import { getListOrderedRenderdMetaIdsByListId } from 'selectors/explore-table-ui';
import { getClientId } from 'selectors/general';
import { getUserFeatureFlags, getUserIsBasic, getUserIsDev } from 'selectors/user';
import { EXTRA_DATA_COLUMN_TYPE } from 'utils/extra-data-utils';

function updateExploreColumnStatusLoading(listId, dataType, recompute, companyMetaIds = null) {
  return (dispatch) => {
    const statusField = OVERRIDE_STATUS_FIELD[dataType];
    dispatch(
      updateColumnDataLoading({
        listId,
        recompute,
        statusField: statusField,
        dataType,
        companyMetaIds
      })
    );
  };
}

function updateExploreColumn(listId, dataType, newCompanies) {
  return (dispatch) => {
    const columns = OVERRIDE_EXTRA_DATA_COLUNNS[dataType];
    dispatch(
      updateListPartialColumnData({
        listId,
        newCompanies: newCompanies?.map(translateMetaRecordForState),
        columns,
        dataType
      })
    );
  };
}

export function loadCompaniesAndUpdateColumns(listId, data_type, companyMetaIds = null) {
  return (dispatch, _getState, apiClient) => {
    apiClient.companyMetas.fetchCompanyMetas(listId, companyMetaIds).then((res) => {
      if (res.ok && res.data) {
        const { company_metas } = res.data;
        dispatch(updateExploreColumn(listId, data_type, company_metas));
      }
    });
  };
}

export function updateExtraDataByTypeForCompaniesInListAction(
  company_list_id,
  data_type,
  recompute = false
) {
  return (dispatch, getState, apiClient) => {
    const client_id = getClientId(getState());
    const payload = { company_list_id, data_type, recompute, client_id };
    dispatch(updateExploreColumnStatusLoading(company_list_id, data_type, recompute));
    apiClient.companyExtraData
      .updateCompaniesExtraData(payload)
      .then(async (res) => {
        if (res.ok && res.data) {
          const { company_metas } = res.data;
          dispatch(updateExploreColumn(company_list_id, data_type, company_metas));
        } else {
          dispatch(
            sendUserEvent(USER_EVENTS.EXTRA_DATA_API_FAILED, { company_list_id, data_type })
          );
          // eslint-disable-next-line no-promise-executor-return
          await new Promise((resolve) => setTimeout(resolve, 30000));
          dispatch(loadCompaniesAndUpdateColumns(company_list_id, data_type));
        }
      })
      .catch(async () => {
        dispatch(sendUserEvent(USER_EVENTS.EXTRA_DATA_API_FAILED, { company_list_id, data_type }));
        // eslint-disable-next-line no-promise-executor-return
        await new Promise((resolve) => setTimeout(resolve, 30000));
        dispatch(loadCompaniesAndUpdateColumns(company_list_id, data_type));
      });
  };
}

export function updateCustomAnalyticsExtraDataForCompaniesInListAction(
  company_list_id,
  data_type,
  recompute = false,
  company_meta_ids = null,
  post_id = null
) {
  return (dispatch, getState, apiClient) => {
    const state = getState();

    const orderedRenderdMetaIds =
      getListOrderedRenderdMetaIdsByListId(state, company_list_id) || null;

    const postId = post_id || new Date().getTime();

    const payload = {
      company_list_id,
      data_type,
      recompute,
      company_meta_ids,
      post_id: postId,
      rendered_ids_by_order: orderedRenderdMetaIds
    };
    dispatch(
      updateExploreColumnStatusLoading(company_list_id, data_type, recompute, company_meta_ids)
    );

    apiClient.companyExtraData
      .updateCompaniesExtraDataInCustomAnalyticsColumn(payload)
      .catch(() => {
        dispatch(loadCompaniesAndUpdateColumns(company_list_id, data_type));
      });
  };
}

export function updateAllExtraDataForCompaniesInListAction(companyListId) {
  return (dispatch, getState) => {
    const reduxState = getState();
    const reactState = getReactState();
    const isDev = getUserIsDev(reactState);
    const isBasic = getUserIsBasic(reactState);
    if (isBasic) {
      // do not trigger updates for basic users as they will fail
      return;
    }
    const featureFlags = getUserFeatureFlags(reactState);
    const typesToDisableForDev = [COMPANY_EXTRA_DATA_TYPES.REVENUES];
    const customColumns = getListCustomColumnsByListId(reduxState, companyListId) || {};
    const companyExtraDataTypes = Object.keys(COMPANY_EXTRA_DATA_TYPES);
    companyExtraDataTypes.forEach((dataType) => {
      const featureFlagName = DATA_TYPE_TO_FEATURE_FLAG[dataType];
      const isDisabled = isDev && typesToDisableForDev.includes(dataType);
      if (!isDisabled && (!featureFlagName || featureFlags?.includes(featureFlagName))) {
        dispatch(updateExtraDataByTypeForCompaniesInListAction(companyListId, dataType));
      }
    });

    const predefinedCAColumns = Object.values(customColumns)?.filter(
      (column) => column.type === 'predefined_custom_analytics'
    );
    predefinedCAColumns?.forEach((column) => {
      dispatch(updateCustomAnalyticsExtraDataForCompaniesInListAction(companyListId, column.id));
    });

    const predefinedExtraDataColumns = Object.values(customColumns)?.filter(
      (column) => column.type === EXTRA_DATA_COLUMN_TYPE
    );
    predefinedExtraDataColumns?.forEach((column) => {
      dispatch(updateExtraDataByTypeForCompaniesInListAction(companyListId, column.id));
    });

    const autoFillColumns = Object.values(customColumns)?.filter(
      (column) => column.info?.auto_fill
    );
    autoFillColumns?.forEach((column) => {
      dispatch(updateCustomAnalyticsExtraDataForCompaniesInListAction(companyListId, column.id));
    });
  };
}
