import NumberRangeFilterComponent from 'components/elements/table/filters/columns/components/NumberRangeFilterComponent';
import PropTypes from 'prop-types';
import { localeStringToNumber } from 'utils/string-utils';

function NumEmployeesColumnFilter({ rows, existFilter, onUpdateFilter }) {
  return (
    <NumberRangeFilterComponent
      columnId="num_employees"
      rows={rows}
      existFilter={existFilter}
      onUpdateFilter={onUpdateFilter}
      rowMap={(number) => Math.round(localeStringToNumber(number))}
    />
  );
}

NumEmployeesColumnFilter.propTypes = {
  rows: PropTypes.array,
  existFilter: PropTypes.object,
  onUpdateFilter: PropTypes.func
};

export default NumEmployeesColumnFilter;
