import { createSelector } from '@reduxjs/toolkit';

export const getSchedulesSlice = (state) => state.schedules;
export const getCompanyListSchedules = (state) => getSchedulesSlice(state)?.companyLists;

// recieve state, listId
export const getCompanyListSchedulesById = createSelector(
  getCompanyListSchedules,
  (_state, listId) => listId,
  (allListSchedules, listId) => {
    const listSchedules = allListSchedules?.[listId];
    if (!listSchedules) return [];
    return Object.values(listSchedules);
  }
);
