import BaseClient from './BaseClient';

const URL_PREFIX = 'company_lists';

export default class CompaniesListsClient extends BaseClient {
  fetchFilters() {
    return this.get(`${URL_PREFIX}/filters`);
  }

  submitList(payload) {
    return this.post(URL_PREFIX, payload);
  }

  fetchList(listId) {
    return this.get(`${URL_PREFIX}/${listId}`);
  }

  fetchListRecordsHistory(listId, recordType, companyExtraDataType) {
    return this.get(
      `${URL_PREFIX}/${listId}/records_history/${recordType}?company_extra_data_type=${companyExtraDataType}`
    );
  }

  deleteList(listId) {
    return this.httpDelete(`${URL_PREFIX}/${listId}`);
  }

  addCompanies(listId, searchTerms) {
    const search_terms = searchTerms.map((name) => name.trim().replaceAll(',', '').trim());
    return this.put(`${URL_PREFIX}/${listId}`, { search_terms });
  }

  copyCompaniesToList(listId, company_list_meta_ids) {
    return this.put(`${URL_PREFIX}/${listId}`, { company_list_meta_ids });
  }

  addCustomColumn(listId, custom_column) {
    return this.put(`${URL_PREFIX}/${listId}/custom_column`, custom_column);
  }

  renameCustomColumn(listId, custom_column_id, name, add_number_if_duplicate) {
    return this.put(`${URL_PREFIX}/${listId}/custom_columns/${custom_column_id}/rename`, {
      name,
      add_number_if_duplicate
    });
  }

  updateCustomColumnInfo(listId, custom_column_id, info) {
    return this.put(`${URL_PREFIX}/${listId}/custom_columns/${custom_column_id}/update_info`, {
      info
    });
  }

  deleteCustomColumn(listId, custom_column_id) {
    return this.httpDelete(`${URL_PREFIX}/${listId}/custom_columns/${custom_column_id}`);
  }

  updateColumnsOrder(listId, columnsOrder) {
    return this.put(`${URL_PREFIX}/${listId}/columns_order`, columnsOrder);
  }

  getAllAdminCompaniesLists() {
    return this.get(`${URL_PREFIX}/admin`);
  }

  getAdminCompaniesListById(listId) {
    return this.get(`${URL_PREFIX}/admin/${listId}`);
  }

  submitHiddenGems(listId) {
    return this.post(`${URL_PREFIX}/${listId}/hidden-gems`, {});
  }

  getView(listId, viewId) {
    return this.get(`${URL_PREFIX}/${listId}/views/${viewId}`);
  }

  submitView(listId, view) {
    if (!view) {
      return this.post(`${URL_PREFIX}/${listId}/views`, {});
    }
    return this.post(`${URL_PREFIX}/${listId}/views`, view);
  }

  setIsMonitored(listId, is_monitored) {
    return this.put(`${URL_PREFIX}/${listId}/is_monitored`, {
      is_monitored,
      delete_monitoring_schedules: true
    });
  }
}
