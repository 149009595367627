import PropTypes from 'prop-types';
import React from 'react';
import { appTheme } from 'theme';

export default function HistoryIcon({ isDisabled }) {
  return (
    <svg width="24px" height="24px" viewBox="0 -960 960 960">
      <path
        d="M479.23-140q-129.92 0-226.46-85.54Q156.23-311.08 141.62-440h61.23Q218-336.38 296.12-268.19 374.23-200 479.23-200q117 0 198.5-81.5t81.5-198.5q0-117-81.5-198.5T479.23-760q-65.54 0-122.84 29.12-57.31 29.11-98.7 80.11h104.62v60H159.23v-203.08h60v94.77q48.69-57.46 116.62-89.19Q403.77-820 479.23-820q70.77 0 132.62 26.77 61.84 26.77 107.84 72.77t72.77 107.85q26.77 61.84 26.77 132.61 0 70.77-26.77 132.61-26.77 61.85-72.77 107.85-46 46-107.84 72.77Q550-140 479.23-140Zm120.08-178.92L450.39-467.85V-680h59.99v187.85l131.08 131.07-42.15 42.16Z"
        fill={
          isDisabled ? appTheme.palette.greyColors.grey150 : appTheme.palette.greyColors.grey300
        }
      />
    </svg>
  );
}

HistoryIcon.propTypes = {
  isDisabled: PropTypes.bool
};
