import LoopIcon from '@mui/icons-material/Loop';
import { Box, Divider, Link, Stack, TextField, Tooltip, Typography } from '@mui/material';
import IconButtonComponent from 'components/IconButtonComponent';
import LinkedinButtonVerbose from 'components/LinkedinButtonVerbose';
import DeleteIcon from 'icons/DeleteIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { limitLength } from 'utils/string-utils';

const COLUMN_WIDTHS = Object.freeze({
  name: '210px',
  website: '200px',
  linkedin: '200px',
  logo: '50px',
  alert: '100%'
});

/* eslint-disable require-unicode-regexp */

const MismatchRowOnDialog = ({
  company,
  onReplaceCompany,
  onDeleteCompany,
  onKeepCompany,
  setResolvedCompany
}) => {
  const [name, setName] = useState(company.parameters?.name || company.name);
  const [isLoading, setIsLoading] = useState(false);
  const website = company.parameters?.company_website;
  const linkedin = company.parameters?.linkedin_url;
  const logo = company.parameters?.logo_url;
  const companyListMetaId = company.company_list_meta_id;
  const companyMetaId = company.company_meta_id;
  const isNotFound = Boolean(company.parameters?.notFoundName);
  const isFailed = Boolean(company.parameters?.failedName);
  const isError = isNotFound || isFailed;

  useEffect(() => {
    setIsLoading(false);
  }, [company]);

  useEffect(() => {
    if (!isError && !company.parameters?.ambiguous_website) {
      setResolvedCompany(company);
    }
  }, [company, setResolvedCompany, isError]);

  const onKeep = () => {
    onKeepCompany(companyListMetaId, companyMetaId, company.parameters?.ambiguous_website);
  };
  const onDelete = () => {
    const deleteFromDB = !isError;
    onDeleteCompany(companyListMetaId, deleteFromDB);
  };

  const reload = () => {
    if (name.trim().length) {
      setIsLoading(true);
      onReplaceCompany(companyListMetaId, name.trim());
    }
  };

  const onDownKey = (event) => {
    if (event?.key === 'Enter') {
      reload();
    }
  };

  const rotateSx = {
    animation: 'spin 2s linear infinite',
    '@keyframes spin': {
      to: { transform: 'rotate(0deg)' },
      from: { transform: 'rotate(360deg)' }
    }
  };
  const basicSx = {
    fontSize: '1.2rem'
  };
  return (
    <Stack
      key={company.company_list_meta_id}
      direction="row"
      padding="8px 24px"
      alignItems="center"
      borderBottom="1px solid"
      borderBottomColor="greyColors.grey100"
      margin="0px 24px">
      <Box width={COLUMN_WIDTHS.logo}>
        {!company.error && !isError && (
          <Box component="img" src={logo} sx={{ width: '32px', height: '32px' }} />
        )}
      </Box>
      <Box width={COLUMN_WIDTHS.name} paddingRight="16px">
        <TextField
          value={name}
          size="small"
          autoFocus={false}
          disabled={isLoading}
          onChange={(event) => setName(event.target.value)}
          onKeyPress={onDownKey}
          sx={{
            input: {
              fontSize: '14px',
              padding: '8px 16px',
              color: 'greyColors.grey300'
            }
          }}
          InputProps={{
            endAdornment: (
              <IconButtonComponent
                tag="Refresh Results"
                disabled={isLoading}
                sx={{ color: 'secondary.secondary1' }}
                onClick={reload}>
                <LoopIcon sx={isLoading ? { ...basicSx, ...rotateSx } : basicSx}></LoopIcon>
              </IconButtonComponent>
            )
          }}
        />
      </Box>
      {isError ? (
        <Typography color="indicator.negative" variant="paragraphSemiBold" paddingRight="24px">
          {isNotFound
            ? 'Did not find match for this company. Try using a different name.'
            : 'Faild to load company. Please try again.'}
        </Typography>
      ) : (
        <React.Fragment>
          <Box width={COLUMN_WIDTHS.website} textAlign="left">
            <Link href={website} underline="none" target="_blank" rel="noreferrer">
              <Tooltip title={website?.replace(/^https?:\/\//, '')}>
                <Typography variant="paragraphBold" color="primary.primary2">
                  {limitLength(website?.replace(/^https?:\/\//, ''), 24)}
                </Typography>
              </Tooltip>
            </Link>
          </Box>
          <Box width={COLUMN_WIDTHS.linkedin}>
            <Link href={linkedin} underline="none" target="_blank" rel="noreferrer">
              {linkedin && <LinkedinButtonVerbose linkedinUrl={linkedin} />}
            </Link>
          </Box>

          <IconButtonComponent onClick={onKeep} sx={{ marginLeft: 'auto', paddingLeft: '24px' }}>
            <Typography variant="paragraphSemiBold" color="primary.primary3_50">
              Keep Company
            </Typography>
          </IconButtonComponent>
          <Divider flexItem orientation="vertical" sx={{ height: '17px', alignSelf: 'center' }} />
        </React.Fragment>
      )}
      <IconButtonComponent sx={{ color: 'red', marginLeft: 'auto' }} onClick={onDelete}>
        <DeleteIcon></DeleteIcon>
        <Typography variant="paragraphMedium">Remove</Typography>
      </IconButtonComponent>
    </Stack>
  );
};

MismatchRowOnDialog.propTypes = {
  company: PropTypes.object,
  companiesLoading: PropTypes.object,
  onReplaceCompany: PropTypes.func,
  onDeleteCompany: PropTypes.func,
  onKeepCompany: PropTypes.func,
  setResolvedCompany: PropTypes.func
};

export default MismatchRowOnDialog;
