import { Stack, Tab, Tabs, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import SelectWithAnswerTypeComponent from 'components/SelectWithAnswerTypeComponent';
import { RESOURCE_TYPE_TO_ICON } from 'components/domain-knowledge/DomainKnowledgeResourceTile';
import SelectFiltersComponent from 'components/tile/discovery/SelectFiltersComponent';
import { DOMAIN_KNOWLEDGE_RESOURCE_OPTIONS } from 'constants/domain-knowledge';
import {
  COMPANIES_OPTIONS,
  COMPANIES_OPTION_OBJECTS,
  OPTIONS,
  OPTION_OBJECTS
} from 'constants/prompt2';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearExploreTableUIState } from 'reducer/explore-table-ui';
import { appTheme } from 'theme';
import { isEmpty } from 'utils/objects-utils';
import { oldPromptConfigToNewPromptConfig } from 'utils/prompt2-utils';

import MultiSectionsSelectComponent from './MultiSectionsSelectComponent';
import PromptEndAdornment from './PromptEndAdornment';
import PromptErrorComponent from './PromptErrorComponent';
import { ANSWER_TYPES } from './PromptInputComponent';
import PromptTextFieldComponent from './PromptTextFieldComponent';
import SlimMultiSelectPromptInputComponent from './SlimMultiSelectComponent';

const buttonStyle = {
  height: '28px'
};

const bigButtonStyle = {
  ...buttonStyle,
  gap: '4px',
  textAlign: 'left',
  width: '100px',
  paddingX: '8px',
  height: '32px'
};

/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
const PromptInputComponent2 = ({
  listId,
  promptConfig,
  setPromptConfig,
  isDev,
  isProccessing,
  handleSubmit,
  showError,
  filters,
  setFilters,
  setShowError,
  getIsInputDisabled,
  dontAllowSubmitCA,
  CompaniesSelector,
  toggleField,
  columns,
  knowledge,
  errorStatus
}) => {
  const [option, setOption] = useState(OPTIONS.COMPANIES);

  const [companiesOption, setCompaniesOption] = useState(
    option === OPTIONS.COMPANIES ? COMPANIES_OPTIONS.QUERY : null
  );

  const dispatch = useDispatch();

  const handleChangeOption = (_event, newOption) => {
    if (!newOption || newOption === option) {
      return;
    }
    if (newOption === OPTIONS.COMPANIES || option === OPTIONS.COMPANIES) {
      toggleField('useDiscover');
    }
    if (newOption === OPTIONS.ASK) {
      toggleField('forEachCompany-off');
    }
    if (newOption === OPTIONS.COLUMN) {
      toggleField('forEachCompany-on');
    }
  };

  const showTextField = () => {
    if (option === OPTIONS.COMPANIES) {
      return companiesOption !== COMPANIES_OPTIONS.PEERS;
    }
    return true;
  };

  const getPlaceholder = () => {
    if (option === OPTIONS.COMPANIES) {
      return COMPANIES_OPTION_OBJECTS?.[companiesOption]?.placeholder || '';
    }
    return OPTION_OBJECTS?.[option]?.placeholder || '';
  };

  const isFilterMissing = Boolean(Object.values(filters).find((filter) => !filter.length));
  const handleCompanyOptionChange = useCallback(
    (_event, newCompanyOption) => {
      if (newCompanyOption === null) {
        return;
      }
      setShowError(false);
      if (newCompanyOption === COMPANIES_OPTIONS.PEERS) {
        setPromptConfig((prev) => ({ ...prev, discoverMethod: newCompanyOption, question: '' }));
      } else {
        setPromptConfig((prev) => ({ ...prev, discoverMethod: newCompanyOption }));
        dispatch(clearExploreTableUIState({ listId }));
      }
    },
    [listId, dispatch, setPromptConfig, setShowError]
  );

  useEffect(() => {
    const newPromptConfig = oldPromptConfigToNewPromptConfig(promptConfig);
    setOption(newPromptConfig.option);
    setCompaniesOption(newPromptConfig.companiesOption);
  }, [promptConfig]);

  const searchPlaces = useMemo(() => {
    const searchItems = [];
    if (promptConfig.searchTheWeb) {
      searchItems.push('web');
    }

    if (promptConfig.useTable) {
      searchItems.push('table');
    }
    if (promptConfig.useDomainKnowledge) {
      searchItems.push('domainKnowledge');
    }
    return searchItems;
  }, [promptConfig]);

  const contextSections = useMemo(() => {
    const sections = [];
    if (promptConfig.useTable) {
      sections.push({
        id: 'columns',
        type: 'multiple',
        title: 'Columns',
        items: Object.values(columns),
        selectItemsSet: new Set(promptConfig.columns),
        onSelectionsChange: (selectedColumns) => {
          setPromptConfig((prev) => ({
            ...prev,
            columns: selectedColumns,
            useColumns: !isEmpty(selectedColumns)
          }));
        }
      });
    }

    if (promptConfig.useDomainKnowledge) {
      const dkTypesSet = Object.values(knowledge).reduce((set, item) => {
        if (item.supporting_resource_type) {
          set.add(item.supporting_resource_type);
        }
        return set;
      }, new Set());
      const domainKnowledgeOptions = [
        ...Object.values(knowledge || {})
          .sort((item1, item2) => {
            if (!item1.supporting_resource_type && !item2.supporting_resource_type) {
              // for items without type (all files/all answers..), keep the original order
              return 1;
            }
            const typeCompare = item1.supporting_resource_type?.localeCompare(
              item2.supporting_resource_type
            );
            if (typeCompare === 0) {
              return item1.name?.localeCompare(item2.name) || -1;
            }
            return typeCompare || -1;
          })
          .map((dk) => ({
            ...dk,
            disabled: !dkTypesSet.has(dk.supporting_resource_type) && !dkTypesSet.has(dk.id),
            icon: RESOURCE_TYPE_TO_ICON[dk.supporting_resource_type] || RESOURCE_TYPE_TO_ICON[dk.id]
          }))
      ];

      sections.push({
        id: 'domain',
        type: 'single',
        title: 'Domain Knowledge',
        items: domainKnowledgeOptions,
        selectItemsSet: new Set(promptConfig.resource ? new Set([promptConfig.resource]) : null),
        onSelectionsChange: (selectedResourceType) => {
          setPromptConfig((prev) => ({ ...prev, resource: selectedResourceType }));
        }
      });
    }
    return sections;
  }, [
    columns,
    promptConfig.useDomainKnowledge,
    promptConfig.columns,
    promptConfig.resource,
    setPromptConfig,
    knowledge,
    promptConfig.useTable
  ]);

  const handleSearchPlaceChange = (newSearchPlaces) => {
    const searchPlaceSet = new Set(newSearchPlaces);
    const dkTypesSet = Object.values(knowledge).reduce((set, item) => {
      if (item.supporting_resource_type) {
        set.add(item.supporting_resource_type);
      }
      return set;
    }, new Set());

    const relevantDomainKnowledge = DOMAIN_KNOWLEDGE_RESOURCE_OPTIONS.filter((dkOption) =>
      dkTypesSet.has(dkOption.id)
    )?.[0];

    setPromptConfig((prev) => ({
      ...prev,
      searchTheWeb: searchPlaceSet.has('web'),
      useTable: searchPlaceSet.has('table'),
      columns: searchPlaceSet.has('table') ? prev.columns || [] : [],
      useDomainKnowledge: searchPlaceSet.has('domainKnowledge'),
      resource: searchPlaceSet.has('domainKnowledge')
        ? prev.resource || relevantDomainKnowledge?.id
        : null
    }));
  };

  const selectStyle = {
    width: 'auto',
    maxWidth: '300px',
    height: '32px',
    textAlign: 'left',
    backgroundColor: 'primary.white',
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'greyColors.grey100'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'greyColors.grey150',
      borderWidth: '1px'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'greyColors.grey150'
    }
  };

  return (
    <Stack
      justifyContent="center"
      gap="12px"
      sx={{
        boxSizing: 'border-box',
        border: '1px solid',
        borderColor: 'greyColors.grey50',
        borderRadius: '16px',
        margin: '16px',
        padding: '12px',
        backgroundColor: 'white',
        boxShadow: '0px 1px 8px 0px #19213D14'
      }}>
      <Tabs
        value={option}
        onChange={handleChangeOption}
        textColor="secondary"
        sx={{
          marginTop: '- 15px',
          borderBottom: '1px solid',
          borderColor: 'greyColors.grey100'
        }}>
        {Object.keys(OPTION_OBJECTS).map((value, index) => {
          const data = OPTION_OBJECTS[value];
          return (
            <Tab
              key={index}
              value={value}
              color="primary"
              sx={{
                height: '25px',
                gap: '8px',
                '&:hover': {
                  color: appTheme.palette.primary.primary25
                },

                '&.Mui-selected': {
                  color: appTheme.palette.primary.primary50
                }
              }}
              label={
                <Stack direction="row" alignItems="center" sx={{ height: '150px' }}>
                  <Typography variant="paragraphMedium">{data.label}</Typography>
                </Stack>
              }
            />
          );
        })}
      </Tabs>
      <Stack direction="row" gap="16px" justifyContent="space-between" alignItems="end">
        {option === OPTIONS.COMPANIES && (
          <Stack gap="4px" textAlign="left">
            <ToggleButtonGroup
              value={companiesOption}
              onChange={handleCompanyOptionChange}
              exclusive
              size="small">
              {Object.keys(COMPANIES_OPTION_OBJECTS).map((value, index) => {
                const data = COMPANIES_OPTION_OBJECTS[value];
                return (
                  <ToggleButton key={index} value={value} sx={bigButtonStyle}>
                    <Typography variant="paragraphMedium">{data.label}</Typography>
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Stack>
        )}
        {[OPTIONS.ASK, OPTIONS.COLUMN].includes(option) && (
          <Stack direction="row" gap="8px" alignItems="end" width="100%">
            <SelectWithAnswerTypeComponent
              currentAnswerType={promptConfig.answerType || Object.keys(ANSWER_TYPES)[0]}
              setAnswerType={(newAnswerType) =>
                setPromptConfig((prev) => ({ ...prev, answerType: newAnswerType }))
              }
              selectStyle={selectStyle}
            />
            <SlimMultiSelectPromptInputComponent
              selectedValues={searchPlaces}
              onValueChange={handleSearchPlaceChange}
              allTag="Search everywhere"
              emptyTag="Select sources"
              sx={{ width: '200px' }}
              selectStyle={selectStyle}
              knowledge={knowledge}
            />
            <MultiSectionsSelectComponent
              continaerSx={{
                ...selectStyle,
                flex: 1,
                maxWidth: '250px'
              }}
              sections={contextSections}
              title="Use specific context"
              emptySectionTooltip="Please choose Table and/or Domain Knowledge "
            />
          </Stack>
        )}
        {option === OPTIONS.COMPANIES && (
          <SelectFiltersComponent
            filters={filters}
            setFilters={setFilters}
            isFilterMissing={isFilterMissing}
          />
        )}
      </Stack>
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        gap="4px"
        sx={{
          border: '1px solid',
          borderColor: showError ? 'indicator.negative' : 'greyColors.grey100',
          borderRadius: '4px',
          padding: '4px 8px',
          backgroundColor: 'white',
          boxShadow: '0px 1px 5px 0px #1018281A',
          color: 'greyColors.grey250'
        }}>
        {showTextField() ? (
          <PromptTextFieldComponent
            {...{
              getPlaceholder,
              getIsInputDisabled,
              handleSubmit,
              isProccessing,
              setPromptConfig,
              promptConfig,
              isDev,
              dontAllowSubmitCA,
              setShowError,
              listId
            }}
          />
        ) : (
          <Stack
            direction="row"
            flexWrap="no-wrap"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="50px">
            {companiesOption === COMPANIES_OPTIONS.PEERS && CompaniesSelector}
            <PromptEndAdornment
              handleSubmit={handleSubmit}
              isProccessing={isProccessing}
              setPromptConfig={setPromptConfig}
              promptConfig={promptConfig}
              isDev={isDev}
              standalone
            />
          </Stack>
        )}
      </Stack>
      {showError && <PromptErrorComponent errorStatus={errorStatus} showError={showError} />}
    </Stack>
  );
};

PromptInputComponent2.propTypes = {
  listId: PropTypes.number,
  promptConfig: PropTypes.object,
  setPromptConfig: PropTypes.func,
  isDev: PropTypes.bool,
  isProccessing: PropTypes.bool,
  handleSubmit: PropTypes.func,
  showError: PropTypes.bool,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  setShowError: PropTypes.func,
  getIsInputDisabled: PropTypes.func,
  dontAllowSubmitCA: PropTypes.bool,
  CompaniesSelector: PropTypes.node,
  toggleField: PropTypes.func,
  columns: PropTypes.object,
  knowledge: PropTypes.object,
  errorStatus: PropTypes.string
};

export default PromptInputComponent2;
