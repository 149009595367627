import { addExploreCustomColumnAction } from 'actions/explore/add-discovery-custom-column-action';
import { getThreadKey } from 'reducer/custom-analytics';
import { mapMetaIdToListMetaId } from 'selectors/companyMetas';
import { getCustomAnalyticsThreadSpecificPostById } from 'selectors/custom-analytics';
import { getColumnValue } from 'utils/customAnalytics-utils';

import { updateBulkOfCustomColumnValuesAction } from './bulk-update-custom-columns-values';

export function enrichContentWithCustomAnalyticsPostResponseAction(
  listId,
  postId,
  columnName,
  onFinish
) {
  return (dispatch, getState) => {
    const state = getState();
    const threadKey = getThreadKey(listId);
    const post = getCustomAnalyticsThreadSpecificPostById(state, threadKey, postId);
    const metaIdToListMetaId = mapMetaIdToListMetaId(state, listId);

    dispatch(
      addExploreCustomColumnAction(
        listId,
        columnName || 'Enriched Column',
        post.body,
        'custom_analytics',
        post.messages?.[0]?.message?.answer_type,
        ({ id: newColumnId }) => {
          const customColumnValues = post.messages.map((row) => ({
            company_list_meta_id: metaIdToListMetaId[row.company_meta_id],
            value: getColumnValue(row, post.recordId)
          }));
          dispatch(
            updateBulkOfCustomColumnValuesAction(listId, newColumnId, customColumnValues, onFinish)
          );
        }
      )
    );
  };
}
