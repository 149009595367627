import RttIcon from '@mui/icons-material/Rtt';
import { Button, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import IconButtonComponent from 'components/IconButtonComponent';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import CustomAnalyticsSubmitIcon from 'icons/CustomAnalyticsSubmitIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCompanyMetasByListId } from 'selectors/explore';
import { getUserFeatureFlags } from 'selectors/user';

import PromptDevMenu from './PromptDevMenu';
import { ANSWER_TYPES } from './PromptInputComponent';

function PromptEndAdornment({
  promptConfig,
  setPromptConfig,
  isProccessing,
  handleSubmit,
  isDev,
  standalone,
  listId
}) {
  const featureFlags = useOldStateSelector(getUserFeatureFlags);
  const newAddColumnFlow = featureFlags.includes('ca_automatically_create_column');
  const numberOfComapnies = useSelector((state) => getCompanyMetasByListId(state, listId))?.length;

  const [anchorEl, setAnchorEl] = useState(false);
  const handleFormatMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Stack
      direction="row"
      gap="4px"
      alignItems="center"
      sx={{ marginTop: standalone ? null : 'auto' }}>
      <Stack direction="row" gap="0px" alignItems="center">
        {isDev && <PromptDevMenu promptConfig={promptConfig} setPromptConfig={setPromptConfig} />}
        {!promptConfig.useDiscover && !newAddColumnFlow && (
          <IconButtonComponent onClick={handleFormatMenuClick}>
            {ANSWER_TYPES[promptConfig.answerType]?.icon || <RttIcon />}
          </IconButtonComponent>
        )}
      </Stack>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}>
        {Object.keys(ANSWER_TYPES).map((answerType, index) => (
          <MenuItem
            value={answerType}
            disabled={ANSWER_TYPES[answerType]?.disabled}
            key={index}
            onClick={() => {
              setPromptConfig((prev) => ({ ...prev, answerType }));
              setAnchorEl(null);
            }}>
            <Stack
              direction="row"
              gap="16px"
              alignItems="center"
              sx={{ color: 'greyColors.grey250' }}>
              {ANSWER_TYPES[answerType].icon}
              <Typography>{ANSWER_TYPES[answerType].label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
      {newAddColumnFlow && promptConfig.forEachCompany && listId && (
        <Tooltip
          title={
            numberOfComapnies > 5
              ? 'See preview for first 5 companies'
              : 'See preview before adding column'
          }>
          <Button
            variant="outlined"
            sx={{
              paddingTop: '7px',
              paddingBottom: '7px'
            }}
            onClick={(event) => handleSubmit(event, true)}
            disabled={isProccessing}>
            <Typography fontSize={13} variant="paragraphBold">
              Preview
            </Typography>
          </Button>
        </Tooltip>
      )}

      <Button
        variant="contained"
        sx={{
          height: '100%',
          width: '32px',
          borderRadius: '4px',
          color: isProccessing ? 'greyColors.grey100' : 'primary.primary100'
        }}
        onClick={handleSubmit}
        disabled={isProccessing}>
        <CustomAnalyticsSubmitIcon />
      </Button>
    </Stack>
  );
}

PromptEndAdornment.propTypes = {
  promptConfig: PropTypes.object,
  setPromptConfig: PropTypes.func,
  isProccessing: PropTypes.bool,
  handleSubmit: PropTypes.func,
  isDev: PropTypes.bool,
  standalone: PropTypes.bool,
  listId: PropTypes.number
};

export default PromptEndAdornment;
