import { Button, Input, Stack, Typography } from '@mui/material';
import { renameFolderByIdAction } from 'actions/folders/rename-folder-by-id-action';
import ModalComponent from 'components/modal/ModalComponent';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFolderNameById } from 'selectors/folders';
import { appTheme } from 'theme';

function RenameFoldersDialog({ open, folderId, onClose }) {
  const dispatch = useDispatch();

  const [newFolderName, setNewFolderName] = useState('');

  const folderName = useSelector((state) => getFolderNameById(state, folderId));

  const closeDialog = () => {
    setNewFolderName('');
    onClose();
  };

  const handleRenameFolder = () => {
    dispatch(renameFolderByIdAction(folderId, newFolderName));
    closeDialog();
  };

  const onDownKey = (event) => {
    if (event?.key === 'Enter' && newFolderName.length > 0) {
      event.preventDefault();
      handleRenameFolder();
    }
  };

  return (
    <ModalComponent
      title="Rename Folder"
      open={open}
      onClose={closeDialog}
      maxWidth={738}
      bottomBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: '8px' }}>
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleRenameFolder}
            disabled={newFolderName.length <= 0}>
            Rename Folder
          </Button>
        </Stack>
      }>
      <Stack direction="column" gap="24px" sx={{ padding: '24px' }}>
        <Typography variant="paragraphMedium">{`Choose new name for the folder "${folderName}"`}</Typography>
        <Input
          placeholder="Enter new folder name here......"
          value={newFolderName}
          size="small"
          autoFocus
          disableUnderline={true}
          onKeyDown={onDownKey}
          sx={{
            flex: 1,
            color: 'greyColors.grey300',
            ...appTheme.typography.paragraph,
            width: '100%'
          }}
          onChange={(event) => setNewFolderName(event.target.value)}
        />
      </Stack>
    </ModalComponent>
  );
}

RenameFoldersDialog.propTypes = {
  open: PropTypes.bool,
  folderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClose: PropTypes.func
};

export default RenameFoldersDialog;
