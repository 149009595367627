import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tab,
  Tabs,
  Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import { useAppUtils } from 'AppUtilsProvider';
import { makeNewUserAction } from 'actions/users/make-new-user-action';
import { ORGANIZATION_SELECTOR_NONE_VALUE } from 'constants/organizations';
import { TOAST_TYPES } from 'constants/toasts';
import { dispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { generatePassword } from 'utils/string-utils';

import OrgnizationSelector from './OrgnizationSelector';

const TAB_VALUE = {
  EMAIL_AND_PASSWORD: 0,
  ONLY_EMAIL: 1
};

export const CreateNewUserDialog = ({ openDialog, setOpenDialog }) => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [nickname, setNickname] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [validPassword, setValidPassword] = useState(true);
  const { addToast } = useAppUtils();
  const [value, setValue] = useState(0);
  const [generatedPassword] = useState(generatePassword());
  const [isLimit, setIslimit] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onClose = () => {
    setOpenDialog(false);
    setEmail(null);
    setPassword(null);
    setIslimit(false);
    setIsReadOnly(false);
  };

  const validatePassword = (pass) => {
    if (!pass?.length) {
      setValidPassword(true);
      return;
    }
    const strongRegex = /^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\W).*$/gu;
    setValidPassword(strongRegex.test(pass));
  };

  const onError = (message) => {
    addToast(message, TOAST_TYPES.ERROR);
  };
  const onSuccess = (message) => {
    addToast(message, TOAST_TYPES.SUCCESS);
  };
  const onSubmitBoth = () => {
    const payload = { email, password, is_limited: isLimit, is_basic: isReadOnly };
    payload.organization_id =
      organizationId === ORGANIZATION_SELECTOR_NONE_VALUE ? null : organizationId;
    dispatch(makeNewUserAction(payload, onError, onSuccess));
    onClose();
  };
  const onSubmitEmail = () => {
    const payload = {
      email,
      password: generatedPassword,
      is_limited: isLimit,
      is_basic: isReadOnly,
      send_invitation: true,
      nickname
    };
    payload.organization_id =
      organizationId === ORGANIZATION_SELECTOR_NONE_VALUE ? null : organizationId;
    dispatch(makeNewUserAction(payload, onError, onSuccess));
    onClose();
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          padding: '24px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px'
        }
      }}
      open={openDialog}
      onClose={onClose}>
      <DialogTitle variant="paragraphBold" color="secondary.secondary6" fontSize="24px">
        Create New User
      </DialogTitle>
      <Divider flexItem />
      <Tabs value={value} onChange={handleChange} textColor="secondary">
        <Tab label="Email and Password" />
        <Tab label="Only Email" />
      </Tabs>
      <FormControl sx={{ margin: 1, width: '30ch' }} variant="outlined">
        <InputLabel>Email</InputLabel>
        <OutlinedInput
          type="text"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          label="Email"
        />
      </FormControl>
      {value === TAB_VALUE.EMAIL_AND_PASSWORD && (
        <FormControl sx={{ margin: 1, width: '30ch' }} variant="outlined" error={!validPassword}>
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
              validatePassword(event.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
          {!validPassword && <FormHelperText>Password is not strong enough</FormHelperText>}
        </FormControl>
      )}
      {value === TAB_VALUE.ONLY_EMAIL && (
        <>
          <FormControl sx={{ margin: 1, width: '30ch' }} variant="outlined">
            <InputLabel>Nickname</InputLabel>
            <OutlinedInput
              type="text"
              value={nickname}
              onChange={(event) => setNickname(event.target.value)}
              label="Nickname"
            />
            <FormHelperText>
              The user will be adressed with this name in the onboarding email
            </FormHelperText>
          </FormControl>
          <FormControl sx={{ margin: 1, width: '30ch' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={'text'}
              value={generatedPassword}
              label="Password"
            />
          </FormControl>
        </>
      )}
      <OrgnizationSelector
        setOrganizationId={setOrganizationId}
        variant="outlined"
        sx={{ margin: 1, width: '30ch' }}
        userDialog
      />
      <FormControlLabel
        sx={{ margin: 1 }}
        control={
          <Checkbox
            onChange={(event) => {
              setIslimit(event.target.checked);
              event.stopPropagation();
            }}
            checked={isLimit}
          />
        }
        label="Limit User"
      />
      <FormControlLabel
        sx={{ margin: 1 }}
        control={
          <Checkbox
            onChange={(event) => {
              setIsReadOnly(event.target.checked);
              event.stopPropagation();
            }}
            checked={isReadOnly}
          />
        }
        label="Read Only User"
      />
      <DialogActions>
        {value === TAB_VALUE.ONLY_EMAIL ? (
          <Tooltip title="Will create a user with this random password and will send to the new user an email to reset his password.">
            <Box>
              <Button
                variant="contained"
                onClick={onSubmitEmail}
                disabled={!email?.length || !nickname?.length}>
                Submit User
              </Button>
            </Box>
          </Tooltip>
        ) : (
          <Button
            variant="contained"
            onClick={onSubmitBoth}
            disabled={!(email?.length && password?.length && validPassword)}>
            Submit User
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

CreateNewUserDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func
};
