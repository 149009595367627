import { createSelector } from '@reduxjs/toolkit';
import { SHARED_WITH_ME_FOLDER_ID } from 'constants/folders';
import { TILE_TYPE_TO_ID_KEY } from 'constants/tiles';

const EMPTY_ARRAY = [];

export const getFoldersSlice = (state) => state.folders;

export const getAllFolders = (state) => getFoldersSlice(state)?.folders;
export const getFolderById = (state, id) => getAllFolders(state)?.[id];
export const getFolderNameById = (state, id) => getFolderById(state, id)?.name;

export const getCurrentFolderId = (state) => getFoldersSlice(state)?.currentFolderId;
export const getCurrentFolderName = (state) => getFolderNameById(state, getCurrentFolderId(state));
export const getFolderResourcesById = (state, id) => getFolderById(state, id)?.folder_resources;
export const getFolderIsShared = (state, id) => {
  const folder = getFolderById(state, id);
  if (!folder) return null;
  return folder && folder.is_shared;
};
export const getFolderOwner = (state, id) => getFolderById(state, id)?.user;
export const getFolderCollaboratorsUsers = (state, id) =>
  getFolderById(state, id)?.collaborators_users;
export const getFolderCollaboratorsOrgs = (state, id) =>
  getFolderById(state, id)?.collaborators_orgs;
export const getFolderCollaboratorsTeams = (state, id) =>
  getFolderById(state, id)?.collaborators_teams;

// recieve state, id
export const getFolderCollaborateInfo = createSelector(
  getFolderCollaboratorsUsers,
  getFolderCollaboratorsOrgs,
  getFolderCollaboratorsTeams,
  getFolderOwner,
  (resourceUsers, resourceOrgs, resourceTeams, resourceOwner) => ({
    resourceUsers,
    resourceOrgs,
    resourceTeams,
    resourceOwner
  })
);

// recieve state, id
export const getAllFolderResources = createSelector(getAllFolders, (allFolders) => {
  if (!allFolders) return [];
  return Object.values(allFolders).reduce(
    (acc, folder) => [...acc, ...(folder.folder_resources || [])],
    []
  );
});

// recieve state
export const getAllFolderRecordIds = createSelector(getAllFolderResources, (allFolderResources) =>
  allFolderResources.map((resource) => resource.record_id).filter(Boolean)
);

// recieve state
export const getAllFolderCompanyListIds = createSelector(
  getAllFolderResources,
  (allFolderResources) =>
    allFolderResources.map((resource) => resource.company_list_id).filter(Boolean)
);

// recieve state, id
export const getFolderRecordIdsById = createSelector(getFolderResourcesById, (folderResources) => {
  if (!folderResources) return [];
  return folderResources.map((resource) => resource.record_id).filter(Boolean);
});

// recieve state, id
export const getFolderCompanyListIdsById = createSelector(
  getFolderResourcesById,
  (folderResources) => {
    if (!folderResources) return [];
    return folderResources.map((resource) => resource.company_list_id).filter(Boolean);
  }
);

export const getCurrentFolderResources = (state) =>
  getFolderById(state, getCurrentFolderId(state))?.folder_resources;
export const getFolderSupportingResourcesById = (state, id) => {
  if (id) {
    return getFolderById(state, id)?.supporting_resources;
  }
  return null;
};
export const getFolderSupportingResourceByIds = (state, id, resourceId) => {
  return getFolderSupportingResourcesById(state, id)?.[resourceId];
};
export const getCurrentFolderSupportingResources = (state) =>
  getFolderSupportingResourcesById(state, getCurrentFolderId(state));

// recieve state, firstFolderId
export const getFolderBreadcrumbs = createSelector(
  (state) => state,
  (_state, firstFolderId) => firstFolderId,
  (state, firstFolderId) => {
    if (!firstFolderId || firstFolderId === SHARED_WITH_ME_FOLDER_ID) return [];
    const currentFolder = getFolderById(state, firstFolderId);
    if (!currentFolder) return [];
    const breadcrumbs = [currentFolder];
    let index = 0;
    while (breadcrumbs[index].parent_id) {
      const folder = getFolderById(state, breadcrumbs[index].parent_id);
      breadcrumbs.unshift(folder);
      index++;
    }
    return breadcrumbs;
  }
);

// recieve state, type, id
export const getFirstParentFolderIdOfResource = createSelector(
  getAllFolders,
  (_state, type) => type,
  (_state, _type, id) => id,
  (allFolders, type, id) => {
    if (!allFolders) return null;
    const idKey = TILE_TYPE_TO_ID_KEY[type];
    return Object.keys(allFolders).find((folderId) =>
      allFolders[folderId]?.folder_resources?.some((resource) => resource[idKey] === id)
    );
  }
);
export const getResourceBreadcrumbs = (state, type, id) => {
  const firstFolderId = getFirstParentFolderIdOfResource(state, type, id);
  if (!firstFolderId) return EMPTY_ARRAY;
  return getFolderBreadcrumbs(state, firstFolderId);
};
export const getCurrentBreadcrumbs = (state) =>
  getFolderBreadcrumbs(state, getCurrentFolderId(state));
