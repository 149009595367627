import { Box, useMediaQuery } from '@mui/material';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import CardComponent from 'components/card/CardComponent';
import PeopleCardComponent from 'components/card/people/PeopleCardComponent';
import PropTypes from 'prop-types';
import React from 'react';
import { appTheme } from 'theme';

import InsightGroupMenu from './InsightGroupMenu';

function GroupInsightsCardsComponent({ recordId, insights, group, element }) {
  const mediumViewport = useMediaQuery(appTheme.breakpoints.up('md'));

  const names =
    group === 'LeadershipOverviewGroup' && element?.data?.columns
      ? element.data.columns.filter((col) => col.id === 'name')[0].values
      : [];

  const picUrl =
    group === 'LeadershipOverviewGroup' && element?.data?.columns
      ? element.data.columns.filter((col) => col.id === 'pic_url')[0].values
      : [];

  const sections =
    group === 'LeadershipOverviewGroup'
      ? names.map((name, index) => ({ type: 'avatar', title: name, data: picUrl[index], id: name }))
      : insights &&
        insights.map((insight) => ({
          type: 'icon',
          data: insight.insight_id,
          title: insight.insight_id,
          id: insight.insight_id
        }));
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: '32px'
      }}>
      <InsightGroupMenu sections={sections} />
      <Box
        sx={{
          width: mediumViewport ? '90%' : '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '32px'
        }}>
        {(insights &&
          insights.map((insight) => (
            <CardComponent
              key={insight.insight_id}
              sectionId={insight.insight_id}
              recordId={recordId}
              insightId={insight.insight_id}
              doNotFetchData
              mode="stretch"
            />
          ))) ||
          (group === 'LeadershipOverviewGroup' &&
            names.map((name, index) => (
              <PeopleCardComponent
                key={index}
                recordId={recordId}
                insightId={name}
                sectionId={name}
                mode="stretch"
              />
            )))}
      </Box>
    </Box>
  );
}

GroupInsightsCardsComponent.propTypes = {
  recordId: PropTypes.string,
  insights: PropTypes.array,
  group: PropTypes.string,
  element: PropTypes.object
};

export default wrapWithError(GroupInsightsCardsComponent);
