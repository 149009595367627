import BaseClient from './BaseClient';

export default class UsersClient extends BaseClient {
  getUser() {
    return this.get(`users`);
  }

  fetchAllUsers() {
    return this.get(`users/all`);
  }

  fetchAllUserSearches(userId) {
    return this.get(`users/${userId}/records`);
  }

  toggleUserActive(userId) {
    return this.put(`users/${userId}/toggle_is_active`, {});
  }

  resendVerificationEmail() {
    return this.post(`users/resend-verification-email`, {});
  }

  sendUserEvent(eventType, context) {
    const event = { event: eventType, context: context };
    return this.post(`users/events`, event);
  }

  createNewUser(payload) {
    return this.post(`users/create-new-user`, payload);
  }

  deleteUser(userId) {
    return this.httpDelete(`users/${userId}`);
  }

  updateUserOrganization(userId, payload) {
    return this.put(`users/${userId}/organization`, payload);
  }

  updateUserIsOrgAdmin(userId, payload) {
    return this.put(`users/${userId}/update-is-org-admin`, payload);
  }

  updateUserIsLimited(userId, payload) {
    return this.put(`users/${userId}/update-is-limited`, payload);
  }

  updateUserIsBasic(userId, payload) {
    return this.put(`users/${userId}/update-is-basic`, payload);
  }

  getUserOrganizationUsers() {
    return this.get(`users/my_organization_users`);
  }

  changeUsername(username) {
    return this.put(`users/change-username`, { username });
  }

  changePicture(picture) {
    return this.put(`users/change-picture`, { picture });
  }

  update_recieve_monitoring_emails(value) {
    return this.post(`users/update_monitoring_email_accept`, { value });
  }
}
