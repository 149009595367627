/* eslint-disable max-lines */
import { createSlice } from '@reduxjs/toolkit';
import { ensureMetaColumnsInOrder } from 'utils/list-utils';

const initialSliceState = {
  exploreLists: {},
  filters: {},
  filtersBypass: {},
  hiddenColumns: {},
  views: {},
  isScreeningOpen: false,
  hideDiscoveryRunningInTable: {},
  mismatchedComapanyMetas: {},
  discoveryRecords: {}
};

export const exploreSlice = createSlice({
  name: 'explore',
  initialState: initialSliceState,
  reducers: {
    addAllExploreLists: (sliceState, { payload }) => {
      const { exploreLists } = payload;
      const oldExplors = sliceState.exploreLists;
      const newExplores = {};
      let discoveryRecords = {};
      Object.values(exploreLists).forEach((companyList) => {
        const { id } = companyList;
        const oldExplore = oldExplors?.[id];
        const listDiscoveries = companyList.discoveryRecords.reduce((prev, search) => {
          prev[search.id] = search;
          return prev;
        }, {});
        discoveryRecords = { ...discoveryRecords, ...listDiscoveries };
        companyList.discoveryIds = Object.keys(listDiscoveries);
        delete companyList.discoveryRecords;

        const extraData = {
          clustersId: companyList?.clustersId || oldExplore?.clustersId,
          lastIteration: companyList?.lastIteration || oldExplore?.lastIteration,
          history: companyList?.history || oldExplore?.history,
          domainKnowledge: companyList?.domainKnowledge || oldExplore?.domainKnowledge
        };
        newExplores[id] = { ...companyList, ...extraData };
        if (companyList?.companyMetas) {
          companyList.companyMetas.forEach((companyMeta, index) => {
            if (
              !companyMeta.enrichment_parameters &&
              oldExplore?.companyMetas?.[index]?.enrichment_parameters &&
              companyMeta.company_meta_id ===
                oldExplore.companyList.companyMetas[index].company_meta_id
            ) {
              companyMeta.enrichment_parameters =
                oldExplore.companyMetas[index].enrichment_parameters;
            }
          });
        } else if (oldExplore?.companyMetas) {
          newExplores[id].companyMetas = [...oldExplore.companyMetas];
        }
      });
      sliceState.discoveryRecords = discoveryRecords;
      sliceState.exploreLists = newExplores;
    },
    addExploreList: (sliceState, { payload }) => {
      const { listId, data } = payload;
      const listDiscoveries = data.discoveryRecords.reduce((prev, search) => {
        prev[search.id] = search;
        return prev;
      }, {});
      sliceState.discoveryRecords = { ...sliceState.discoveryRecords, ...listDiscoveries };
      data.discoveryIds = Object.keys(listDiscoveries);
      delete data.discoveryRecords;
      const { parameters } = data || {};
      sliceState.exploreLists[listId] = {
        ...data,
        parameters: {
          ...parameters,
          columns_order: ensureMetaColumnsInOrder(parameters?.columns_order)
        }
      };
    },

    deleteExploreList: (sliceState, { payload }) => {
      const { listId } = payload;
      const listDiscoveries = sliceState.exploreLists[listId].discoveryIds;
      listDiscoveries.forEach((discoveryId) => {
        delete sliceState.discoveryRecords?.[discoveryId];
      });
      delete sliceState.exploreLists[listId];
    },
    updateFiltersBypassWarnings: (sliceState, { payload }) => {
      const { listId, companyMetaId } = payload;
      if (!sliceState.filtersBypass?.[listId]?.[companyMetaId]) {
        return;
      }
      sliceState.filtersBypass[listId][companyMetaId].warn = true;
    },
    clearFiltersBypass: (sliceState, { payload }) => {
      const { listId } = payload;
      delete sliceState.filtersBypass[listId];
    },
    addCompaniesToList: (sliceState, { payload }) => {
      const { listId, companyMetas, notFound } = payload;
      const oldCompaniesData = sliceState.exploreLists?.[listId]?.companyMetas;
      if (!oldCompaniesData) return;
      const newCompaniesData = [...oldCompaniesData];
      if (companyMetas?.length) {
        newCompaniesData.push(...companyMetas);
        if (!sliceState.filtersBypass?.[listId]) {
          sliceState.filtersBypass[listId] = {};
        }
        sliceState.filtersBypass[listId] = {
          ...sliceState.filtersBypass[listId],
          ...companyMetas.reduce((prev, company) => {
            prev[company.company_meta_id] = { name: company.name };
            return prev;
          }, {})
        };
      }
      if (notFound?.length) {
        const not_found_rows = notFound.map((name) => ({
          company_meta_id: -1,
          name: name,
          notFound: true,
          parameters: { name, notFound: true, companyListId: listId }
        }));
        newCompaniesData.push(...not_found_rows);
      }
      sliceState.exploreLists[listId].companyMetas = newCompaniesData;
    },
    removeNotFoundCompanyFromList: (sliceState, { payload }) => {
      const { listId, companyName } = payload;
      const companies = sliceState.exploreLists[listId]?.companyMetas;
      if (!companies) return;
      sliceState.exploreLists[listId].companyMetas =
        companies?.filter((company) => !(company.name === companyName && company?.notFound)) || [];
    },
    replaceNotFoundCompanyInList: (sliceState, { payload }) => {
      const { listId, oldName, companyMeta } = payload;
      const companies = sliceState.exploreLists[listId]?.companyMetas;
      if (!companies) return;
      sliceState.exploreLists[listId].companyMetas = companies.map((company) => {
        return company.name === oldName && company?.notFound ? companyMeta : company;
      });
    },

    updateMismatchCompaniesInList: (sliceState, { payload }) => {
      const { listId } = payload;
      const companies = sliceState.exploreLists[listId]?.companyMetas;
      if (!companies) return;
      const mismatchedComapanyMetas = companies.filter(
        (company) =>
          !company.is_discovered &&
          company.parameters?.ambiguous_website &&
          !company.ignore_ambiguous
      );
      sliceState.mismatchedComapanyMetas[listId] = mismatchedComapanyMetas.length
        ? mismatchedComapanyMetas
        : null;
    },

    clearExploreMismatchCompanies: (sliceState, { payload }) => {
      const { listId } = payload;
      sliceState.mismatchedComapanyMetas[listId] = null;
    },

    removeExploreMismatchCompany: (sliceState, { payload }) => {
      const { listId, companyListMetaId } = payload;

      const mismatchedComapanyMetas =
        sliceState.mismatchedComapanyMetas?.[listId]?.filter(
          (company) => company.company_list_meta_id !== companyListMetaId
        ) || [];
      sliceState.mismatchedComapanyMetas[listId] = mismatchedComapanyMetas?.length
        ? mismatchedComapanyMetas
        : null;
    },

    replaceExploreMismatchCompany: (sliceState, { payload }) => {
      const { oldCompanyListMetaId, newCompanyMeta, notFoundName, failedName, listId } = payload;
      const mismatchedComapanyMetas =
        sliceState.mismatchedComapanyMetas[listId]?.map((company) => {
          if (company?.company_list_meta_id === oldCompanyListMetaId) {
            if (notFoundName || failedName) {
              const newCompany = {
                ...company,
                parameters: {
                  ...company.parameters,
                  notFoundName: notFoundName,
                  failedName: failedName
                }
              };
              return newCompany;
            }
            return newCompanyMeta;
          }
          return company;
        }) || [];
      sliceState.mismatchedComapanyMetas[listId] = mismatchedComapanyMetas;
    },

    replaceCompanyInList: (sliceState, { payload }) => {
      const { oldCompanyListMetaId, newCompanyMeta, listId } = payload;
      const companyMetas = sliceState.exploreLists[listId]?.companyMetas;
      if (!companyMetas) return;
      const isOldCompanyThere = companyMetas.find(
        (company) => company?.company_list_meta_id === oldCompanyListMetaId
      );
      const newCompanyMetas = companyMetas.map((company) => {
        return company?.company_list_meta_id === oldCompanyListMetaId ? newCompanyMeta : company;
      });
      if (!isOldCompanyThere) {
        newCompanyMetas.push(newCompanyMeta);
      }
      sliceState.exploreLists[listId].companyMetas = newCompanyMetas;
    },

    addCompanyAtIndex: (sliceState, { payload }) => {
      // make sure that it's working!!
      const { listId, company, index } = payload;
      const companyMetas = sliceState.exploreLists[listId]?.companyMetas;
      if (!companyMetas) return;
      companyMetas.splice(index, 0, company);
    },

    deleteCompaniesFromListByIds: (sliceState, { payload }) => {
      const { listId, ids } = payload;
      const idsSet = new Set(ids);
      const oldCompanies = sliceState.exploreLists[listId]?.companyMetas;
      if (!oldCompanies) return;
      sliceState.exploreLists[listId].companyMetas = oldCompanies.filter(
        (company) => !idsSet.has(company.company_list_meta_id)
      );
    },
    updateExploreFilters: (sliceState, { payload }) => {
      const { listId, filters } = payload;
      if (!sliceState.filters[listId]) {
        sliceState.filters[listId] = {};
      }
      sliceState.filters[listId] = filters;
    },
    updateFiltersByColumnId: (sliceState, { payload }) => {
      const { listId, columnId, data } = payload;
      delete sliceState.filtersBypass[listId];
      if (!sliceState.filters[listId]) {
        sliceState.filters[listId] = {};
      }
      if (!sliceState.filters[listId][columnId]) {
        sliceState.filters[listId][columnId] = {};
      }
      sliceState.filters[listId][columnId] = {
        ...sliceState.filters[listId][columnId],
        data
      };
    },
    clearFilterByColumnId: (sliceState, { payload }) => {
      const { listId, columnId } = payload;
      delete sliceState.filtersBypass[listId];
      if (!sliceState.filters[listId]) return;
      const newFilters = { ...sliceState.filters[listId] };
      delete newFilters[columnId];
      sliceState.filters[listId] = newFilters;
    },
    clearAllFilters: (sliceState, { payload }) => {
      const { listId } = payload;
      delete sliceState.filtersBypass[listId];
      if (!sliceState.filters[listId]) return;
      const newFilters = { ...sliceState.filters };
      delete newFilters[listId];
      sliceState.filters = newFilters;
    },
    updateExploreHiddenColumns: (sliceState, { payload }) => {
      const { listId, columnsIds, isHidden, reset } = payload;
      if (!sliceState.hiddenColumns[listId]) {
        sliceState.hiddenColumns[listId] = {};
      }
      if (reset) {
        sliceState.hiddenColumns[listId] = columnsIds.reduce((prev, columnId) => {
          prev[columnId] = isHidden;
          return prev;
        }, {});
      } else {
        columnsIds.forEach((columnId) => {
          sliceState.hiddenColumns[listId][columnId] = isHidden;
        });
      }
    },
    updateExploreView: (sliceState, { payload }) => {
      const { listId, view } = payload;
      if (!sliceState.views[listId]) {
        sliceState.views[listId] = {};
      }
      sliceState.views[listId][view.id] = view;
    },
    deleteExploreView: (sliceState, { payload }) => {
      const { listId, viewId } = payload;

      if (!sliceState.views[listId]?.[viewId]) return;

      const newViews = { ...sliceState.views[listId] };
      delete newViews[viewId];
      sliceState.views[listId] = newViews;
    },
    updateIsScreeningOpen: (sliceState, { payload }) => {
      const { isOpen } = payload;
      sliceState.isScreeningOpen = isOpen;
    },
    hideDiscoveryRunningBarInTable: (sliceState, { payload }) => {
      const { discoveryId } = payload;
      sliceState.hideDiscoveryRunningInTable[discoveryId] = true;
    },
    updateListPartialColumnData: (sliceState, { payload }) => {
      const { listId, newCompanies, columns, dataType } = payload;
      const companies = sliceState.exploreLists[listId]?.companyMetas;
      if (!companies) return;
      const newCompaniesPartialData = newCompanies.reduce((prev, company) => {
        if (columns) {
          prev[company.company_meta_id] = columns.reduce(
            (acc, column) => ({
              ...acc,
              [column]: company[column]
            }),
            {}
          );
        } else {
          prev[company.company_meta_id] = company?.company_extra_data?.[dataType];
        }

        return prev;
      }, {});

      const newCompanyMetas = companies.map((company) => {
        const companyPartialData = newCompaniesPartialData?.[company.company_meta_id];
        if (!companyPartialData) return { ...company };
        if (columns) {
          return { ...company, ...companyPartialData };
        }
        return {
          ...company,
          company_extra_data: {
            ...company.company_extra_data,
            [dataType]: { ...companyPartialData }
          }
        };
      });
      sliceState.exploreLists[listId].companyMetas = newCompanyMetas;
    },
    updateColumnDataLoading: (sliceState, { payload }) => {
      const { listId, statusField, recompute, companyMetaIds, dataType } = payload;
      const companies = sliceState.exploreLists[listId]?.companyMetas;
      if (!companies) return;
      sliceState.exploreLists[listId].companyMetas = companies.map((company) => {
        if (companyMetaIds && !companyMetaIds.includes(company.company_meta_id)) {
          return company;
        }
        const status = statusField
          ? company?.[statusField]
          : company.company_extra_data?.[dataType]?.status;

        const missingFromExtraData = !status || status === 'NONE' || status === 'FAILED';
        const missingFromCC = !company?.customColumns?.[dataType];

        if ((missingFromExtraData && missingFromCC) || recompute) {
          const returnValue = { ...company };
          if (statusField) {
            returnValue[statusField] = 'LOADING';
          }
          if (dataType && returnValue?.company_extra_data) {
            if (!returnValue.company_extra_data?.[dataType]) {
              returnValue.company_extra_data[dataType] = {};
            }
            returnValue.company_extra_data[dataType].status = 'LOADING';
          }
          return returnValue;
        }
        return company;
      });
    },
    updateNameInExploreList: (sliceState, { payload }) => {
      const { listId, newName } = payload;
      if (sliceState.exploreLists[listId]) {
        sliceState.exploreLists[listId].name = newName;
      }
    },
    updateDiscoveryProgress: (sliceState, { payload }) => {
      const { searchId, progress, message } = payload;
      if (!sliceState.discoveryRecords[searchId]) {
        return;
      }
      if (
        !sliceState.discoveryRecords[searchId]?.progress ||
        progress > sliceState.discoveryRecords[searchId].progress
      ) {
        sliceState.discoveryRecords[searchId].progress = progress;
        if (message) {
          sliceState.discoveryRecords[searchId].progressMessage = message;
        }
      }
    },
    updateDiscoveryStatuses: (sliceState, { payload }) => {
      const { searchId, statuses } = payload;
      if (!sliceState.discoveryRecords[searchId]) {
        return;
      }
      sliceState.discoveryRecords[searchId].statuses = statuses;
    },
    addExploreCustomColumn: (sliceState, { payload }) => {
      const { listId, columnSetting } = payload;
      if (!sliceState.exploreLists[listId]) return;
      const customColumns = sliceState.exploreLists[listId]?.customColumns;
      const newCustomColumns = customColumns
        ? { ...customColumns, [columnSetting.id]: { ...columnSetting } }
        : { [columnSetting.id]: { ...columnSetting } };
      sliceState.exploreLists[listId].customColumns = newCustomColumns;
    },
    updateExploreCustomColumnData: (sliceState, { payload }) => {
      const { listId, companyListMetaId, columnId, value } = payload;
      const companyMetas = sliceState.exploreLists[listId]?.companyMetas;
      if (!companyMetas) return;
      const newCompanyMetas = companyMetas.map((meta) => {
        if (meta.company_list_meta_id !== companyListMetaId) return meta;
        const company_extra_data = meta.company_extra_data ? { ...meta.company_extra_data } : {};
        if (company_extra_data[columnId]) {
          company_extra_data[columnId].data = value;
          return { ...meta, company_extra_data };
        }

        const customColumns = meta.customColumns ? { ...meta.customColumns } : {};
        customColumns[columnId] = value;
        return { ...meta, customColumns };
      });
      sliceState.exploreLists[listId].companyMetas = newCompanyMetas;
    },
    updateExploreCustomColumnName: (sliceState, { payload }) => {
      const { listId, columnId, newColumnName } = payload;
      const customColumns = sliceState.exploreLists[listId]?.customColumns;
      if (!customColumns) return;
      const customColumn = customColumns?.[columnId] || {};
      sliceState.exploreLists[listId].customColumns[columnId] = {
        ...customColumn,
        name: newColumnName
      };
    },
    updateExploreCustomColumnInfo: (sliceState, { payload }) => {
      const { listId, columnId, newColumnInfo } = payload;
      const customColumns = sliceState.exploreLists[listId]?.customColumns;
      if (!customColumns) return;
      const customColumn = customColumns?.[columnId] || {};
      sliceState.exploreLists[listId].customColumns[columnId] = {
        ...customColumn,
        info: newColumnInfo
      };
    },
    deleteExploreCustomColumnName: (sliceState, { payload }) => {
      const { listId, columnId } = payload;
      delete sliceState.exploreLists[listId]?.customColumns?.[columnId];
    },

    updateExploreFiles: (sliceState, { payload }) => {
      const { listId, companyListMetaId, filesInfo } = payload;
      const companyMetas = sliceState.exploreLists[listId]?.companyMetas;
      if (!companyMetas) return;
      sliceState.exploreLists[listId].companyMetas = companyMetas.map((meta) => {
        if (meta.company_list_meta_id !== companyListMetaId) return meta;
        return { ...meta, files: filesInfo };
      });
    },
    updateClustersIdInExplore: (sliceState, { payload }) => {
      const { clustersId, listId } = payload;
      if (sliceState.exploreLists[listId]) {
        sliceState.exploreLists[listId].clustersId = clustersId;
      }
    },
    updateClustersIdsInExplore: (sliceState, { payload }) => {
      const { listId, searchIds } = payload;
      if (sliceState.exploreLists[listId]) {
        sliceState.exploreLists[listId].clusters_record_ids = searchIds;
      }
    },
    updateIgnoreAmbiguousInExploreCompany: (sliceState, { payload }) => {
      const { companyMetaId, ignoreAmbiguous, listId } = payload;
      const companyMetas = sliceState.exploreLists[listId]?.companyMetas;
      if (!companyMetas) return;
      sliceState.exploreLists[listId].companyMetas = companyMetas.map((companyMeta) => {
        if (companyMeta.company_meta_id === companyMetaId) {
          const newCompanyMeta = { ...companyMeta, ignore_ambiguous: ignoreAmbiguous };
          return newCompanyMeta;
        }
        return companyMeta;
      });
    },
    addExploreDomainKnowledgeResource: (sliceState, { payload }) => {
      const { listId, resource } = payload;
      if (!sliceState.exploreLists[listId]) return;

      if (!sliceState.exploreLists[listId]?.domainKnowledge) {
        sliceState.exploreLists[listId].domainKnowledge = [];
      }
      sliceState.exploreLists[listId].domainKnowledge.push(resource);
    },
    deleteExploreDomainKnowledgeResource: (sliceState, { payload }) => {
      const { listId, resourceId } = payload;
      if (sliceState.exploreLists[listId]?.domainKnowledge) {
        sliceState.exploreLists[listId].domainKnowledge = sliceState.exploreLists[
          listId
        ].domainKnowledge.filter((item) => item.id !== resourceId);
      }
    },
    setIsListMonitored: (sliceState, { payload }) => {
      const { listId, isMonitored } = payload;
      if (sliceState.exploreLists[listId]) {
        sliceState.exploreLists[listId].isMonitored = isMonitored;
      }
    },

    updateColumnsHistoryInList: (sliceState, { payload }) => {
      const { listId, companies_history, data_type } = payload;
      if (sliceState.exploreLists[listId]) {
        const newColumnName = `${data_type}-history`;
        const originalCustomColumns = sliceState.exploreLists[listId].customColumns;
        sliceState.exploreLists[listId].customColumns = {
          ...originalCustomColumns,
          [newColumnName]: {
            ...originalCustomColumns[data_type],
            id: newColumnName,
            name: `${originalCustomColumns[data_type].name}-previous`
          }
        };
        const newCompanyMetas = sliceState.exploreLists[listId].companyMetas.map((company) => {
          const dataFromListMeta = company.customColumns?.[data_type];
          const oldData = companies_history[company.company_meta_id]?.[1]?.data || dataFromListMeta;
          const timestamp = companies_history[company.company_meta_id]?.[1]?.last_updated;
          return {
            ...company,
            customColumns: {
              ...company.customColumns,
              [newColumnName]: {
                ...oldData,
                timestamp
              }
            }
          };
        });
        sliceState.exploreLists[listId].companyMetas = newCompanyMetas;
      }
    },
    updateCompanyListColumnsOrder: (sliceState, { payload }) => {
      const { listId, columnsOrder } = payload;
      if (sliceState.exploreLists[listId]) {
        if (!sliceState.exploreLists[listId].parameters) {
          sliceState.exploreLists[listId].parameters = {};
        }
        sliceState.exploreLists[listId].parameters.columns_order =
          ensureMetaColumnsInOrder(columnsOrder);
      }
    }
  }
});

export const {
  addAllExploreLists,
  addExploreList,
  deleteExploreList,
  updateListPartialColumnData,
  updateColumnDataLoading,
  clearFilterByColumnId,
  clearAllFilters,
  updateExploreView,
  deleteExploreView,
  updateExploreHiddenColumns,
  updateFiltersByColumnId,
  updateIsScreeningOpen,
  hideDiscoveryRunningBarInTable,
  updateDiscoveryStatuses,
  updateDiscoveryProgress,
  updateMismatchCompaniesInList,
  addCompaniesToList,
  addCompanyAtIndex,
  clearExploreMismatchCompanies,
  addExploreCustomColumn,
  updateExploreCustomColumnData,
  updateExploreCustomColumnName,
  updateExploreCustomColumnInfo,
  deleteExploreCustomColumnName,
  updateExploreFiles,
  deleteCompaniesFromListByIds,
  removeExploreMismatchCompany,
  replaceCompanyInList,
  removeNotFoundCompanyFromList,
  replaceNotFoundCompanyInList,
  replaceExploreMismatchCompany,
  updateNameInExploreList,
  updateClustersIdInExplore,
  updateClustersIdsInExplore,
  updateIgnoreAmbiguousInExploreCompany,
  updateExploreFilters,
  addExploreDomainKnowledgeResource,
  deleteExploreDomainKnowledgeResource,
  updateFiltersBypassWarnings,
  clearFiltersBypass,
  updateExploreFolderId,
  setIsListMonitored,
  updateColumnsHistoryInList,
  updateCompanyListColumnsOrder
} = exploreSlice.actions;

export default exploreSlice.reducer;
