import { Box, Collapse, IconButton, Stack, Tooltip } from '@mui/material';
import { changeDiscoveryNameAction } from 'actions/explore/change-discovery-name';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import IconButtonComponent from 'components/IconButtonComponent';
import ResizeableWidthStack from 'components/ResizeableWidthStack';
import CustomAnalyticsWidget from 'components/discovery/CustomAnalyticsWidget';
import { DiscoveryTitle } from 'components/discovery/DiscoveryTitle';
import ExploreQueriesHistory from 'components/discovery/ExploreQueriesHistory';
import { CUSTOM_ANALYTICS_PLACEMENT } from 'constants/custom-analytics';
import { useWindowSize } from 'hooks/useWindowSize';
import CollapseIcon from 'icons/CollapseIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

function DiscoveryLeftPane({
  listId,
  heights,
  rows,
  onError,
  isDev,
  name,
  isCollapsed,
  setIsCollapsed,
  disableCollapse
}) {
  const reduxDispatch = useDispatch();
  const windowSize = useWindowSize();

  const onCollapse = () => {
    if (!disableCollapse) {
      setIsCollapsed(!isCollapsed);
    }
  };

  const onTitleChange = (newTitle) => {
    if (newTitle === name) return;
    reduxDispatch(changeDiscoveryNameAction(listId, newTitle, onError));
  };

  return (
    <Stack
      direction="column"
      overflowx="hidden"
      overflowy="auto"
      sx={{
        height: `${windowSize[1] - heights.navBarHeight}px`,
        backgroundColor: 'primary.white',
        borderRight: '1px solid',
        borderColor: 'greyColors.grey100'
      }}>
      <Collapse
        in={isCollapsed}
        orientation="horizontal"
        sx={{ flex: isCollapsed ? 1 : 0, height: '100%' }}>
        <Stack width="32px" height="100%" backgroundColor="background.bg50" borderRadius="4px">
          <Tooltip title={disableCollapse ? 'You are in basic plan' : null}>
            <Box
              component="span"
              onClick={onCollapse}
              sx={{
                cursor: disableCollapse ? 'default' : 'pointer',
                boxSizing: 'border-box',
                height: '40px',
                padding: '8px',
                backgroundColor: disableCollapse ? 'transparent' : 'greyColors.grey100',
                '&:hover': {
                  backgroundColor: disableCollapse ? 'transparent' : 'greyColors.grey50'
                }
              }}>
              <IconButton
                disabled={disableCollapse}
                sx={{
                  padding: 0,
                  width: '16px',
                  transform: 'rotate(180deg)',
                  '&:hover': {
                    backgroundColor: 'greyColors.grey50'
                  }
                }}>
                <CollapseIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Stack>
      </Collapse>
      <Collapse
        in={!isCollapsed}
        orientation="horizontal"
        sx={{ flex: isCollapsed ? 0 : 1, height: '100%' }}>
        <ResizeableWidthStack
          maxWidth={700}
          minWidth={565}
          initialWidth={Math.max(565, windowSize[0] / 3)}
          direction="column"
          gap="8px"
          sx={{
            backgroundColor: 'primary.white',
            overflowY: 'auto',
            outline: 'none',
            zIndex: 1,
            borderRadius: '4px'
          }}>
          <Stack
            direction="row"
            gap="16px"
            alignItems="center"
            margin="12px 16px"
            minHeight="40px"
            paddingBottom="4px"
            paddingLeft="8px"
            sx={{
              overflowY: 'auto',
              borderBottom: '1px solid',
              borderColor: 'greyColors.grey100'
            }}>
            {name && (
              <DiscoveryTitle
                title={name}
                onTitleChange={onTitleChange}
                numCompanies={rows?.length}></DiscoveryTitle>
            )}
            <ExploreQueriesHistory listId={listId} />
            <IconButtonComponent
              id="collapse-explore-pannel"
              onClick={onCollapse}
              sx={{ marginLeft: 'auto', color: 'black' }}>
              <CollapseIcon />
            </IconButtonComponent>
          </Stack>
          <CustomAnalyticsWidget
            listId={listId}
            isDev={isDev}
            placement={CUSTOM_ANALYTICS_PLACEMENT.EXPLORE}
          />
        </ResizeableWidthStack>
      </Collapse>
    </Stack>
  );
}
DiscoveryLeftPane.propTypes = {
  listId: PropTypes.number,
  name: PropTypes.string,
  rows: PropTypes.array,
  heights: PropTypes.object,
  onError: PropTypes.func,
  isDev: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  setIsCollapsed: PropTypes.func,
  disableCollapse: PropTypes.bool
};

export default wrapWithError(DiscoveryLeftPane);
