/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */
import { ListItemIcon, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

export default function NestedMenuItemComponent({
  startIcon,
  endIcon,
  text,
  subMenuItems,
  subMenuAnchorEl,
  setSubMenuAnchorEl
}) {
  const [isHoverItem, setIsHoverItem] = useState(false);
  const [isHoverSubmMenu, setIsHoverSubMenu] = useState(false);

  const mainItemRef = useRef(null);

  const handleSubmenuClose = () => {
    setSubMenuAnchorEl(null);
    setIsHoverItem(false);
    setIsHoverSubMenu(false);
  };

  useEffect(() => {
    setTimeout(() => {
      if (!isHoverItem && !isHoverSubmMenu) {
        setSubMenuAnchorEl(null);
      }
    }, 100);
  }, [isHoverItem, isHoverSubmMenu, subMenuAnchorEl, setSubMenuAnchorEl]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (mainItemRef.current) {
        const rect = mainItemRef.current.getBoundingClientRect();
        const isInside =
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom;
        setIsHoverItem(isInside);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <MenuItem
      ref={mainItemRef}
      onMouseEnter={(event) => {
        setIsHoverItem(true);
        setSubMenuAnchorEl(event.currentTarget);
      }}
      onMouseLeave={() => setIsHoverItem(false)}
      sx={{ paddingRight: 0 }}>
      {startIcon && <ListItemIcon>{startIcon}</ListItemIcon>}
      {text && <ListItemText>{text}</ListItemText>}
      {endIcon && <ListItemIcon>{endIcon}</ListItemIcon>}
      {subMenuAnchorEl && (
        <Menu
          anchorEl={subMenuAnchorEl}
          open={Boolean(subMenuAnchorEl)}
          onClose={handleSubmenuClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}>
          <Stack
            onMouseEnter={() => {
              setIsHoverSubMenu(true);
              setIsHoverItem(false);
            }}
            onMouseLeave={() => {
              setIsHoverSubMenu(false);
            }}>
            {subMenuItems.map((item, index) => (
              <MenuItem key={index} onClick={item.onClick}>
                <ListItemText>{item.text}</ListItemText>
                {item.endIcon && <ListItemIcon>{item.endIcon}</ListItemIcon>}
              </MenuItem>
            ))}
          </Stack>
        </Menu>
      )}
    </MenuItem>
  );
}

NestedMenuItemComponent.propTypes = {
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  text: PropTypes.string,
  subMenuItems: PropTypes.array,
  subMenuAnchorEl: PropTypes.object,
  setSubMenuAnchorEl: PropTypes.func
};
