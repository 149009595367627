import { Box, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import ArrowIcon from 'icons/ArrowIcon';
import PropTypes from 'prop-types';
import React from 'react';

import { ANSWER_TYPES } from './discovery/PromptInputComponent';

function SelectWithAnswerTypeComponent({ currentAnswerType, setAnswerType, selectStyle }) {
  const handleChange = (event) => {
    setAnswerType(event.target.value);
  };

  return (
    <FormControl>
      <Select
        labelId="column-select-label"
        id="column-select"
        value={currentAnswerType}
        onChange={handleChange}
        onKeyDownCapture={(event) => event.stopPropagation()}
        IconComponent={() => {
          return (
            <Box
              sx={{
                position: 'absolute',
                cursor: 'pointer',
                pointerEvents: 'none',
                right: 14,
                top: 5,
                height: '15px',
                width: '15px'
              }}>
              <ArrowIcon rotation="180" />
            </Box>
          );
        }}
        renderValue={(value) => (
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              color: 'greyColors.grey250',
              paddingLeft: '8px',
              paddingTop: '4px',
              paddingBottom: '4px'
            }}>
            {ANSWER_TYPES[value]?.icon}
          </Stack>
        )}
        sx={{ ...selectStyle, '.MuiSelect-select': { padding: 0 } }}>
        {Object.keys(ANSWER_TYPES).map((answerType) => (
          <MenuItem key={answerType} value={answerType}>
            <Stack gap="8px" direction="row" alignItems="center">
              {ANSWER_TYPES[answerType] && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '20px',
                    height: '20px'
                  }}>
                  {ANSWER_TYPES[answerType].icon}
                </Box>
              )}
              <Typography variant="paragraphMedium">{ANSWER_TYPES[answerType].label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectWithAnswerTypeComponent.propTypes = {
  currentAnswerType: PropTypes.string,
  setAnswerType: PropTypes.func,
  selectStyle: PropTypes.object
};

export default SelectWithAnswerTypeComponent;
