import { CUSTOM_ANALYTICS_PLACEMENT } from 'constants/custom-analytics';
import { getCompanyMetasMergedRowsByCompanyListId } from 'selectors/companyMetas';
import {
  getCompanyListNameByListId,
  getListCustomColumnsByListId,
  getListDomainKnowledgeByListId
} from 'selectors/explore';
import { getExploreUiColumnsByListId } from 'selectors/explore-table-ui';
import { getCurrentFolderId, getCurrentFolderSupportingResources } from 'selectors/folders';
import { combineDomainKnowledge } from 'utils/domain-knowledge-utils';
import {
  getCompaniesAndColumns,
  promptConfigToDisplayPrefix,
  promptToPromptConfig
} from 'utils/prompts-utils';

export function exportDiscoveryQueriesHistoryAction(listId, queries) {
  return (_dispatch, getState) => {
    const state = getState();
    const listName = getCompanyListNameByListId(state, listId);
    const columnsSettings = getExploreUiColumnsByListId(state, listId);
    const rows = getCompanyMetasMergedRowsByCompanyListId(state, listId);
    const customColumns = getListCustomColumnsByListId(state, listId);
    const listDomainKnowledge = getListDomainKnowledgeByListId(state, listId);
    const folderId = getCurrentFolderId(state);
    const folderDomainKnowledge = getCurrentFolderSupportingResources(state, folderId);

    const { columns } = getCompaniesAndColumns(listId, rows, customColumns, columnsSettings, false);
    const domainKnowledge = combineDomainKnowledge(listDomainKnowledge, folderDomainKnowledge);

    /* eslint-disable require-unicode-regexp */
    const csv = queries
      .map((post) => {
        const { query, body } = post;
        const prompyConfig = promptToPromptConfig(query, body.answerType, null, body.model);
        const prefix = promptConfigToDisplayPrefix(
          prompyConfig,
          columns,
          domainKnowledge,
          CUSTOM_ANALYTICS_PLACEMENT.EXPLORE,
          true
        );
        const formattedQuery = `${prefix} ${prompyConfig.question}`;
        return `${post.body.createdAt},${formattedQuery.replace(/,/g, '')}`;
      })
      .join('\n');
    const csvContent = `data:text/csv;charset=utf-8,Time Submitted,Question\n${csv}\n`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${listName} queries history.csv`);
    document.body.appendChild(link);
    link.click();
  };
}
