import removeMd from 'remove-markdown';

export function toTitleCase(string) {
  const words = string.split(' ');
  return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function alphabeticalCaseInsensitive(string1, string2) {
  return string1.toLowerCase().localeCompare(string2.toLowerCase());
}

export function toTypeTitle(artType) {
  return toTitleCase(artType.split('_').join(' '));
}

export function simplifyDomain(string) {
  return new URL(`https://${string}`).hostname.replace('www.', '');
}

export function extractLinkedinName(string) {
  const parts = string.split('/');
  const companyIndex = parts.indexOf('company');
  if (parts.length > companyIndex && companyIndex !== -1) {
    return ` / ${parts[companyIndex + 1]}`;
  }
  return null;
}

export function limitLength(string, num) {
  if (!string || string.length < num) {
    return string;
  }
  return `${string.slice(0, num - 3)}...`;
}

export const parseNewLines = (value) => value.replace(/\\n/gu, '\n');

export const stringToCodeInt = (value) => {
  const val1 = value.toLowerCase().charCodeAt(0) - 96;
  const val2 = value.toLowerCase().charCodeAt(1) - 96;
  const val3 = value.toLowerCase().charCodeAt(2) - 96;
  return val1 + val2 + val3;
};

export function isValidUrl(urlString) {
  let url = null;
  try {
    url = new URL(urlString);
  } catch {
    return false;
  }
  return url?.protocol === 'http:' || url?.protocol === 'https:';
}

export function localeStringToNumber(localeString) {
  if (!localeString) {
    return null;
  }
  // Get the locale's grouping and decimal separators.
  // Need to use number that includes a decimal point and more than 3 digits
  const parts = new Intl.NumberFormat().formatToParts(12345.6);
  const groupSeparator = parts.find((part) => part.type === 'group').value;
  const decimalSeparator = parts.find((part) => part.type === 'decimal').value;

  // eslint-disable-next-line require-unicode-regexp
  const groupSeparatorRegex = new RegExp(`\\${groupSeparator}`, 'g');
  // Remove grouping separator (e.g., commas or periods in thousands)
  const cleanedString = localeString.replace(groupSeparatorRegex, '');
  // Replace the locale's decimal separator with a dot (.)
  const normalizedString = cleanedString.replace(decimalSeparator, '.');

  // Convert to number
  return parseFloat(normalizedString);
}

export const removeMarkdown = (text, dryRun) => {
  if (!text) {
    return null;
  }
  if (dryRun) {
    return text;
  }
  if (typeof text !== 'string') {
    return text;
  }
  /* eslint-disable prefer-named-capture-group  */
  /* eslint-disable require-unicode-regexp  */
  const cleaned = removeMd(text)
    .replace(/\r?\n|\r/g, '')
    .replaceAll('\\n', '')
    .replace(/(?:__|[*#])|\[(.*?)\]\(.*?\)/gu, '$1');
  if (cleaned.charAt(0) === '=') {
    return cleaned.slice(1);
  }
  return cleaned;
};

export function generatePassword() {
  const lowers = 'abcdefghijklmnopqrstuvwxyz';
  const uppers = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const symbols = '!@#$%^&*()_-=+';
  const length = Math.floor(Math.random() * (8 - 20) + 20);
  let password = '';
  password += lowers.charAt(Math.floor(Math.random() * lowers.length));
  password += uppers.charAt(Math.floor(Math.random() * uppers.length));
  password += numbers.charAt(Math.floor(Math.random() * numbers.length));
  password += symbols.charAt(Math.floor(Math.random() * symbols.length));
  for (let index = 4; index < length; index++) {
    const charType = Math.floor(Math.random() * 4);
    switch (charType) {
      case 0:
        password += lowers.charAt(Math.floor(Math.random() * lowers.length));
        break;
      case 1:
        password += uppers.charAt(Math.floor(Math.random() * uppers.length));
        break;
      case 2:
        password += numbers.charAt(Math.floor(Math.random() * numbers.length));
        break;
      case 3:
        password += symbols.charAt(Math.floor(Math.random() * symbols.length));
        break;
      default:
        password += lowers.charAt(Math.floor(Math.random() * lowers.length));
    }
  }
  const shuffledPassword = password
    .split('')
    .sort(() => {
      return 0.5 - Math.random();
    })
    .join('');
  return shuffledPassword;
}
