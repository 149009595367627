import { makeNewDryDiscoveryAction } from 'actions/discoveries/make-new-discovery-action';
import EnterNameStepComponent from 'components/discovery/EnterNameStepComponent';
import ModalComponent from 'components/modal/ModalComponent';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getExploreFilteredCompanies } from 'selectors/explore-table-ui';

export function CreateListDialog({ open, onClose, listId, folderId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState();
  const filteredCompanies = useSelector((reduxState) =>
    getExploreFilteredCompanies(reduxState, listId)
  );
  const closeDialog = () => {
    setName(null);
    onClose();
  };

  const onNegativButtonClick = (event) => {
    event.stopPropagation();
    closeDialog();
  };

  const onPositiveButtonClick = () => {
    dispatch(makeNewDryDiscoveryAction(name, navigate, listId, filteredCompanies, folderId));
    closeDialog();
  };
  return (
    <ModalComponent
      open={open}
      maxWidth={1024}
      allowEditTitle={true}
      bulk={true}
      onTitleChange={(newName) => setName(newName)}
      onClose={closeDialog}>
      <EnterNameStepComponent
        name={name}
        setName={setName}
        onCancel={onNegativButtonClick}
        onCreateReport={onPositiveButtonClick}
      />
    </ModalComponent>
  );
}

CreateListDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  listId: PropTypes.number,
  folderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
