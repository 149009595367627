import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import IconButtonComponent from 'components/IconButtonComponent';
import CustomAnalyticsFeedMessageComponent from 'components/discovery/CustomAnalyticsFeedMessageComponent';
import PromptDisplayComponent from 'components/discovery/PromptDisplayComponent';
import { CUSTOM_ANALYTICS_PLACEMENT } from 'constants/custom-analytics';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import DeleteIcon from 'icons/DeleteIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getUserIsDev } from 'selectors/user';

import DeleteDynamicCardDialog from './DeleteDynamicCardDialog';

function DeepDiveDynamicCardsGroupComponent({ deepDiveId, cards }) {
  const [hoverCardId, setHoverCardId] = useState(false);
  const [deleteCardId, setDeleteCardId] = useState(false);
  const isDev = useOldStateSelector(getUserIsDev);

  return (
    <Card
      sx={{
        padding: { xs: '16px', md: '32px' },
        borderRadius: '4px',
        boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)'
      }}>
      <CardHeader
        sx={{ padding: 0 }}
        title={
          <Typography
            variant="h3"
            textAlign="left"
            color={'greyColors.grey300'}
            sx={{
              borderBottom: '4px solid',
              width: 'fit-content',
              paddingBottom: '8px'
            }}>
            Custom Cards
          </Typography>
        }
      />
      <CardContent>
        <Stack direction="column" gap="32px">
          {cards.map((card) => (
            <Stack
              key={card.id}
              direction="column"
              alignItems="flex-start"
              gap="16px"
              onMouseEnter={() => setHoverCardId(card.id)}
              onMouseLeave={() => setHoverCardId(null)}
              sx={{
                position: 'relative',
                padding: '16px',
                '&:hover': {
                  boxShadow: '0px 0px 10px 1px rgba(0, 19, 195, 0.15)',
                  borderRadius: '4px'
                }
              }}>
              <IconButtonComponent
                tag="delete"
                sx={{
                  padding: 0,
                  display: hoverCardId === card.id ? 'block' : 'none',
                  position: 'absolute',
                  top: '16px',
                  right: '16px'
                }}
                onClick={() => setDeleteCardId(card.id)}>
                <DeleteIcon />
              </IconButtonComponent>
              <Typography variant="h4" color="secondary.secondary2">
                {card.data.name}
              </Typography>
              <Stack direction="row" alignItems="center" gap="8px">
                <Typography variant="paragraphBold">Question:</Typography>
                <PromptDisplayComponent
                  companyMetaId={card.data.company_meta_id}
                  prompt={card.data.question}
                  mode="plain"
                  deepDiveId={deepDiveId}
                />
              </Stack>
              <CustomAnalyticsFeedMessageComponent
                companyMetaId={card.data.company_meta_id}
                body={card.data.answer}
                followUpQuestions={card.data?.follow_up_questions}
                sources={card.data?.sources}
                reasoning={card.data?.reasoning}
                answerType={card.data?.answer_type}
                responseType={card.data?.responseType}
                placement={CUSTOM_ANALYTICS_PLACEMENT.DEEP_DIVE}
                toolCallHistory={isDev && card.data?.tool_call_history}
              />
            </Stack>
          ))}
        </Stack>
      </CardContent>
      <DeleteDynamicCardDialog
        open={Boolean(deleteCardId)}
        deepDiveId={deepDiveId}
        cardId={deleteCardId}
        onClose={() => setDeleteCardId(null)}
      />
    </Card>
  );
}

DeepDiveDynamicCardsGroupComponent.propTypes = {
  deepDiveId: PropTypes.number,
  cards: PropTypes.array
};

export default DeepDiveDynamicCardsGroupComponent;
