import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input
} from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { addToRunDynamicCardAction } from 'actions/runs/add-to-run-dynamic-card-action';
import { TOAST_TYPES } from 'constants/toasts';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

function AddDeepDiveDynamicCardDialog({
  open,
  onClose,
  deepDiveId,
  companyMetaId,
  enrichedPostId
}) {
  const dispatch = useDispatch();
  const toastRef = useRef();
  const { addToast, cancelToast } = useAppUtils();

  const [cardName, setCardName] = useState('');

  const closeDialog = () => {
    setCardName('');
    onClose();
  };

  const handleColumnNameChange = (event) => {
    setCardName(event.target.value);
  };

  const hnaldeAddDynamicCard = () => {
    dispatch(
      addToRunDynamicCardAction(deepDiveId, companyMetaId, enrichedPostId, cardName, () => {
        cancelToast(toastRef.current);
        toastRef.current = null;
        const id = addToast('Adding new card succeed', TOAST_TYPES.SUCCESS);
        setTimeout(() => cancelToast(id), 2000);
      })
    );
    toastRef.current = addToast('Adding new card', TOAST_TYPES.INFO);
    closeDialog();
  };

  const onKeyDown = (event) => {
    if (event?.key === 'Enter') {
      hnaldeAddDynamicCard();
      event.preventDefault();

      return;
    }
    event.stopPropagation();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { width: '500px' } }}>
      <DialogTitle id="alert-dialog-title">Add dynamic card with custom analytics data</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Box sx={{ padding: '24px 0' }}>
          <Input
            value={cardName}
            onChange={handleColumnNameChange}
            placeholder="Write the new card name here"
            sx={{
              width: '100%'
            }}
            onKeyDown={onKeyDown}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button onClick={hnaldeAddDynamicCard} disabled={cardName.length <= 0}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddDeepDiveDynamicCardDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  deepDiveId: PropTypes.number,
  companyMetaId: PropTypes.number,
  enrichedPostId: PropTypes.string
};

export default AddDeepDiveDynamicCardDialog;
