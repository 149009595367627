export const ensureMetaColumnsInOrder = (columnsOrder) => {
  if (!columnsOrder) {
    return columnsOrder;
  }
  const metaColumns = columnsOrder.filter((column) => column.endsWith('-meta'));
  if (metaColumns.length === 0) {
    return columnsOrder;
  }
  const columns = columnsOrder.filter((column) => !column.endsWith('-meta'));
  const sortedColumns = [];
  columns.forEach((column) => {
    sortedColumns.push(column);
    if (metaColumns.includes(`${column}-meta`)) {
      sortedColumns.push(`${column}-meta`);
    }
  });
  return sortedColumns;
};
