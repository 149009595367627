import { Input, Slider, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { appTheme } from 'theme';

function NumberRangeFilterComponent({ columnId, rowMap, rows, existFilter, onUpdateFilter }) {
  const handleChange = (_event, newValue) => {
    if (!existFilter || existFilter?.[0] !== newValue[0] || existFilter?.[1] !== newValue[1]) {
      onUpdateFilter(newValue);
    }
  };

  const [minNumber, maxNumber] = useMemo(() => {
    const allColumnValues = rows
      .map((row) => rowMap(row[columnId]))
      .filter((rowColumnvalue) => rowColumnvalue);
    return [Math.floor(Math.min(...allColumnValues)), Math.ceil(Math.max(...allColumnValues))];
  }, [rows, columnId, rowMap]);

  const value = existFilter?.data || [minNumber, maxNumber];

  return (
    <Stack direction="row" alignItems="center" gap="16px" sx={{ width: 300 }}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: '74px',
          height: '32px',
          backgroundColor: 'primary.white',
          border: '1px solid',
          borderColor: 'greyColors.grey100',
          borderRadius: '4px'
        }}>
        <Input
          value={value[0]}
          hiddenLabel
          type="number"
          size="small"
          inputProps={{
            sx: {
              width: '60px',
              textAlign: 'center',
              padding: 0,
              margin: 0
            }
          }}
          sx={{
            ...appTheme.typography.tiny,
            '&:before': { content: 'none' },
            '&:after': { content: 'none' },
            '-moz-appearance': 'textfield',
            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0
            }
          }}
          onChange={(event) => handleChange(event, [event.target.value, value[1]])}
        />
      </Stack>
      <Slider min={minNumber} max={maxNumber} value={value} onChange={handleChange} size="small" />
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: '74px',
          height: '32px',
          backgroundColor: 'primary.white',
          border: '1px solid',
          borderColor: 'greyColors.grey100',
          borderRadius: '4px'
        }}>
        <Input
          value={value[1]}
          hiddenLabel
          type="number"
          size="small"
          inputProps={{
            sx: {
              width: '60px',
              textAlign: 'center',
              padding: 0,
              margin: 0
            }
          }}
          sx={{
            ...appTheme.typography.tiny,
            '&:before': { content: 'none' },
            '&:after': { content: 'none' },
            '-moz-appearance': 'textfield',
            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0
            }
          }}
          onChange={(event) => handleChange(event, [value[0], event.target.value])}
        />
      </Stack>
    </Stack>
  );
}

NumberRangeFilterComponent.propTypes = {
  columnId: PropTypes.string,
  rowMap: PropTypes.func,
  rows: PropTypes.array,
  existFilter: PropTypes.object,
  onUpdateFilter: PropTypes.func
};

NumberRangeFilterComponent.defaultProps = {
  rowMap: (value) => value
};

export default NumberRangeFilterComponent;
