import { ACTIONS } from 'constants/actions';

function updateUsersData(data) {
  return { type: ACTIONS.ADD_USERS_DATA, data };
}

function translateOrganizationForUserState(organization) {
  if (!organization) {
    return {};
  }
  return {
    organizationName: organization.name,
    organizationLogoUrl: organization.logo_url,
    ownerOrganizationId: organization.id
  };
}

function translateUserForState(user) {
  return {
    id: user.id,
    email: user.email,
    isActive: user.is_active,
    isAdmin: user.is_admin,
    isDev: user.is_dev,
    isBasic: user.is_basic,
    isOrgAdmin: user.is_org_admin,
    signDate: user.sign_date,
    isLimited: user.is_limited,
    ...translateOrganizationForUserState(user?.organization)
  };
}

export function loadAllUsers() {
  return (_state, dispatch, apiClient) => {
    apiClient.users.fetchAllUsers().then((res) => {
      if (res.ok && res.data) {
        const { users } = res.data;
        const usersData = users.reduce((acc, dataItem) => {
          const data = { ...dataItem };
          return { ...acc, [data.id]: translateUserForState(data) };
        }, {});
        dispatch(updateUsersData(usersData));
      }
    });
  };
}
