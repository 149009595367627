import MultipleSelectionFilerComponent from 'components/elements/table/filters/columns/components/MultipleSelectionFilerComponent';
import PropTypes from 'prop-types';

function PartnershipLevelColumnFilter({ rows, existFilter, onUpdateFilter, columnId }) {
  return (
    <MultipleSelectionFilerComponent
      columnId={columnId}
      rows={rows}
      existFilter={existFilter}
      onUpdateFilter={onUpdateFilter}
      rowMap={(data) => data?.value?.partnership_level}
    />
  );
}

PartnershipLevelColumnFilter.propTypes = {
  rows: PropTypes.array,
  existFilter: PropTypes.object,
  onUpdateFilter: PropTypes.func,
  columnId: PropTypes.string
};

export default PartnershipLevelColumnFilter;
