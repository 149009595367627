import PropTypes from 'prop-types';
import React from 'react';

export default function DeleteIcon({ useDefaultColor }) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" id="delete">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.625 7.19553H19C19.5523 7.19553 20 7.64325 20 8.19553V8.02615H18.4546L18.0832 17.5103C18.0399 18.5825 17.158 19.4295 16.0848 19.4295H8.87731C7.80132 19.4295 6.91817 18.5782 6.87866 17.5029L6.54129 8.02615H5L5 8.19553C5 7.64325 5.44772 7.19553 6 7.19553H8.37501L8.375 6.57056C8.375 5.46599 9.27043 4.57056 10.375 4.57056H14.625C15.7296 4.57056 16.625 5.46598 16.625 6.57055L16.625 7.19553ZM15.625 7.19553L15.625 6.57055C15.625 6.01827 15.1773 5.57056 14.625 5.57056H10.375C9.82272 5.57056 9.375 6.01827 9.375 6.57056L9.37501 7.19553H15.625ZM7.54197 8.02615L7.87799 17.4662C7.89774 18.0039 8.33932 18.4295 8.87731 18.4295H16.0848C16.6214 18.4295 17.0623 18.006 17.084 17.4699L17.4538 8.02615H7.54197Z"
        fill={useDefaultColor ? '#0B1C38' : 'currentColor'}
      />
    </svg>
  );
}

DeleteIcon.propTypes = {
  useDefaultColor: PropTypes.bool
};

DeleteIcon.defaultProps = {
  useDefaultColor: false
};
