import { loadUserOrganizationUsersAction } from 'actions/users/load-user-organization-users-action';

export function deleteOrganizationUserAction(userId, onError, onSuccess) {
  return (_state, dispatch, apiClient) => {
    apiClient.users
      .deleteUser(userId)
      .then((res) => {
        if (res.ok) {
          onSuccess?.('User was deleted successfully!');
          dispatch(loadUserOrganizationUsersAction());
        } else {
          onError?.(res.data.message);
        }
      })
      .catch(() => {
        onError?.('Failed to delete user');
      });
  };
}
