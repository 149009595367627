import React from 'react';

function LinkIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2009 10.7991C12.5638 10.1623 11.7 9.80456 10.7992 9.80456C9.89844 9.80456 9.03455 10.1623 8.39748 10.7991L5.99498 13.2008C5.35791 13.8379 5 14.7019 5 15.6029C5 16.5039 5.35791 17.3679 5.99498 18.005C6.63206 18.6421 7.49613 19 8.39709 19C9.29805 19 10.1621 18.6421 10.7992 18.005L12.0001 16.8042M10.799 13.2009C11.4361 13.8377 12.3 14.1954 13.2007 14.1954C14.1015 14.1954 14.9654 13.8377 15.6024 13.2009L18.0049 10.7992C18.642 10.1621 18.9999 9.29805 18.9999 8.39709C18.9999 7.49613 18.642 6.63206 18.0049 5.99498C17.3679 5.35791 16.5038 5 15.6028 5C14.7019 5 13.8378 5.35791 13.2007 5.99498L11.9999 7.19584"
        stroke="#584DA5"
        strokeLinecap="round"
        strokeLinejoin="round"></path>
    </svg>
  );
}

export default LinkIcon;
