import { loadUserOrganizationUsersAction } from 'actions/users/load-user-organization-users-action';
import { getUser } from 'selectors/user';
import { generatePassword } from 'utils/string-utils';

export function makeNewViewerUserInOrgAction(newViewerEmail, onError, onSuccess) {
  return (state, dispatch, apiClient) => {
    const user = getUser(state);
    const tmpPassword = generatePassword();
    const payload = {
      email: newViewerEmail,
      password: tmpPassword,
      is_limited: false,
      is_basic: true,
      send_invitation: true,
      organization_id: user.organization.id,
      nickname: ''
    };
    apiClient.users
      .createNewUser(payload)
      .then((res) => {
        if (res.ok) {
          onSuccess?.('User was created successfully!');
          dispatch(loadUserOrganizationUsersAction());
        } else {
          onError?.(res?.message || res?.data?.message || 'Failed to create user');
        }
      })
      .catch((res) => {
        onError?.(res?.message || res?.data?.message || 'Failed to create user');
      });
  };
}
