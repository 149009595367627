import { Button, Menu, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { appTheme } from 'theme';

import TableRowLoading from './TableRowLoading';

export default function RenderGenericExtraData({ value, color, extraParams }) {
  const loading = value?.value?.loading;
  const [showTooltip, setShowTooltip] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (loading) {
    return <TableRowLoading></TableRowLoading>;
  }
  const data = value?.value;
  const fields = extraParams?.info?.fields;
  const fieldsWithData = fields && Object.values(fields).filter((field) => data[field.id]);
  const thereIsNoData = !fieldsWithData || fieldsWithData.length === 0;
  if (thereIsNoData) {
    return (
      <Stack width="100%" padding={0}>
        <Stack direction="row" alignItems="center" width="100%" gap="8px" paddingX="8px">
          <Typography variant="paragraph" color={color} noWrap display="block">
            No Data
          </Typography>
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack width="100%" padding={0}>
      <Button
        onClick={handleClick}
        fullWidth
        disableRipple
        sx={{
          '&:hover': { backgroundColor: 'unset' }
        }}>
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          gap="8px"
          onMouseEnter={() => setShowTooltip(!showTooltip)}
          onMouseLeave={() => setShowTooltip(false)}>
          <Typography variant="paragraph" color={color} noWrap display="block">
            Click for Data
          </Typography>
        </Stack>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        MenuListProps={{
          style: { backgroundColor: appTheme.palette.secondary.secondary1 }
        }}>
        <Stack
          sx={{
            backgroundColor: 'secondary.secondary1',
            color: 'white',
            padding: '4px'
          }}>
          {fieldsWithData.map((field, index) => {
            return (
              <Stack key={index} direction="row" gap="8px">
                <Typography variant="paragraph" color="greyColors.grey100">
                  {field.name}:
                </Typography>
                <Typography variant="paragraph" color="greyColors.grey100">
                  {data[field.id]}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Menu>
    </Stack>
  );
}

export const generatePartialRenderGenericExtraData = (extraParams) => {
  return function partialComponent(props) {
    return <RenderGenericExtraData {...props} extraParams={extraParams} />;
  };
};

RenderGenericExtraData.propTypes = {
  value: PropTypes.object,
  color: PropTypes.string,
  variant: PropTypes.string,
  extraParams: PropTypes.object
};

RenderGenericExtraData.defaultProps = {
  color: 'greyColors.grey300',
  variant: 'paragraph'
};
