import { updateExploreCustomColumnName } from 'reducer/explore-slice';

export function editDiscoveryCustomColumnNameAction(
  listId,
  columnId,
  newColumnName,
  add_number_if_duplicate
) {
  return (dispatch, _getState, apiClient) => {
    dispatch(updateExploreCustomColumnName({ listId, columnId, newColumnName }));

    apiClient.companiesLists
      .renameCustomColumn(listId, columnId, newColumnName, add_number_if_duplicate)
      .then((res) => {
        if (res?.data?.new_name && res.data.new_name !== newColumnName) {
          dispatch(
            updateExploreCustomColumnName({ listId, columnId, newColumnName: res.data.new_name })
          );
        }
      });
  };
}
