import { loadAllUsers } from 'actions/users/load-all-users-action';

export function updateUserIsOrgAdminAction(userId, isOrgAdmin) {
  return (_state, dispatch, apiClient) => {
    apiClient.users.updateUserIsOrgAdmin(userId, { is_org_admin: isOrgAdmin }).then((res) => {
      if (res.ok) {
        dispatch(loadAllUsers());
      }
    });
  };
}
