import { getReactState } from 'actions/get-react-state-action';
import { ACTIONS } from 'constants/actions';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import { getLastDiscoveryByListId } from 'selectors/discoveries';
import { getDiscoveryClustersByClustersId } from 'selectors/discovery-clusters';
import { getClustersIdByListId } from 'selectors/explore';
import { makeBase64Download } from 'utils/download-utils';

function updateRunFilesStateMetaData(runId, isLoading) {
  const data = { runId: runId, isLoading: isLoading };
  return { type: ACTIONS.MAKE_RUN_FILES, data };
}

export function makeRunFiles(runId, service, recordId) {
  return (_disaptch, getState, apiClient) => {
    const reduxState = getState();
    const oldState = getReactState();
    oldDispatch(updateRunFilesStateMetaData(runId, true));
    let search_id = runId;
    let actualRecordId = recordId;
    if (service === 'discovery') {
      const discovery = getLastDiscoveryByListId(reduxState, runId);
      search_id = discovery.id;
      actualRecordId = discovery.recordId;
    } else if (service === 'tagging') {
      const clustersId = getClustersIdByListId(reduxState, runId);
      const clusters = getDiscoveryClustersByClustersId(oldState, clustersId);
      search_id = clusters?.id;
      actualRecordId = clusters?.recordId;
    }
    if (!search_id) {
      oldDispatch(updateRunFilesStateMetaData(runId, false));
      return;
    }
    apiClient.runs.fetchIntermediate(search_id, service).then((res) => {
      if (res.ok && res.data) {
        const fileName = `${actualRecordId}_${service}.zip`;
        makeBase64Download(res.data.zip, fileName);
      }
      oldDispatch(updateRunFilesStateMetaData(runId, false));
    });
  };
}
