export const MAX_CSV_SIZE = 500;
export const MAX_COMPANIES_SELECTION = 20;
export const LIST_ACTIONS = Object.freeze({
  EXTEND: 'extend',
  DEEP_DIVE: 'deepDive'
});
export const LIST_ACTION_MAX_COMPANIES = Object.freeze({
  EXTEND: 5,
  DEEP_DIVE: 20
});
export const LIST_ACTION_DESCRIPTION = Object.freeze({
  EXTEND:
    'Enrich your long-list with more companies by using a key phrase or by selecting a subset of “seed companies” and find more similar ones.',
  DEEP_DIVE:
    'Create single company snapshots on team structure, leadership, IP, growth and perception.'
});
export const maxCompaniesFromActionType = (actionType) => {
  const type = Object.keys(LIST_ACTIONS).find((key) => LIST_ACTIONS[key] === actionType);
  if (Object.keys(LIST_ACTION_MAX_COMPANIES).includes(type)) {
    return LIST_ACTION_MAX_COMPANIES[type];
  }
  return MAX_COMPANIES_SELECTION;
};
export const generateDisableActionMessage = (actionType) => {
  return `Please select up to ${maxCompaniesFromActionType(actionType)} companies`;
};
export const getActionDescription = (actionType) => {
  const type = Object.keys(LIST_ACTIONS).find((key) => LIST_ACTIONS[key] === actionType);
  return LIST_ACTION_DESCRIPTION[type];
};
export const NOT_FOUND_COMPANIES_ERROR_TEXT =
  'We couldn’t upload some of the companies you submitted.\nTry typing again the company name or website in the table above.';
export const MAX_CSV_SIZE_ERROR_TEXT = `Your list could not be uploaded. Make sure your file contains a maximum of ${MAX_CSV_SIZE} companies and try uploading it again.`;

export const DISCOVERY_FILTERS = Object.freeze({
  ALL: { value: 1, label: 'All' },
  PRIVATE: { value: 2, label: 'Mine Only' },
  SHARED: { value: 3, label: 'Shared Only' }
});

export const DISCOVERY_TYPES = Object.freeze({
  TEXT: 'TEXT',
  PEERS: 'PEERS',
  PARTNERS: 'PARTNERS',
  URL: 'URL'
});

export const DISCOVERY_TYPES_TO_SCHEDULE_DISOVERY_TYPES = Object.freeze({
  TEXT: 'discovery_via_search',
  PEERS: 'discovery_via_companies'
});

export const DISCOVERY_TYPES_TITLES = Object.freeze({
  [DISCOVERY_TYPES.TEXT]: 'Query',
  [DISCOVERY_TYPES.PEERS]: 'Peer Group',
  [DISCOVERY_TYPES.PARTNERS]: 'Partner Of',
  [DISCOVERY_TYPES.URL]: 'Custom URL'
});

export const DRY_DISCOVERY_TYPE = 'dry_discovery';

export const LEGACY_CUSTOM_URL_DISCOVERY_TYPE = 'Partners Directory URL';
