import { sendUserEvent } from 'actions/users/send-user-event-action';
import { USER_EVENTS } from 'constants/userEvents';
import { getThreadKey } from 'reducer/custom-analytics';
import { addDeepDiveDynamicCard, deleteDeepDiveDynamicCard } from 'reducer/deep-dive';
import { getCustomAnalyticsThreadSpecificPostById } from 'selectors/custom-analytics';
import { v4 as uuidv4 } from 'uuid';

export function addToRunDynamicCardAction(deepDiveId, companyMetaId, postId, cardName, onFinish) {
  return (dispatch, getState, apiClient) => {
    const getMessageValue = (message, recordId) => {
      return {
        answer: message.answer,
        answer_type: message.answer_type,
        reasoning: message.reasoning,
        tool_call_history: message.tool_call_history,
        sources: message.sources ? message.sources : null,
        custom_analytics_record_id: recordId
      };
    };

    const getPostValue = (post) => {
      return {
        ...post.body,
        type: 'custom_analytics',
        plan: post.plan,
        ...getMessageValue(
          post?.messages?.length > 0
            ? post.messages[0]?.message || post.messages[0]
            : post?.message,
          post.recordId
        )
      };
    };

    const dataType = `dynamic_card${uuidv4()}`;
    const threadKey = getThreadKey(null, companyMetaId);
    const post = getCustomAnalyticsThreadSpecificPostById(getState(), threadKey, postId);
    const content = getPostValue(post);
    const card = { name: cardName, ...content };

    dispatch(addDeepDiveDynamicCard({ deepDiveId, card: { id: dataType, data: card } }));
    apiClient.runs
      .addDynamicCard(deepDiveId, {
        data: card,
        data_type: dataType
      })
      .then((res) => {
        if (res.ok) {
          onFinish?.();
        } else {
          dispatch(deleteDeepDiveDynamicCard({ deepDiveId, cardId: dataType }));
        }
        dispatch(
          sendUserEvent(USER_EVENTS.DYNAMIC_CARD_SUBMISSION, {
            deepDiveId,
            cardName,
            cardId: dataType,
            query: post.query
          })
        );
      })
      .catch(() => {
        dispatch(deleteDeepDiveDynamicCard({ deepDiveId, cardId: dataType }));
      });
  };
}
