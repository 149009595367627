import { Box, Stack, useMediaQuery } from '@mui/material';
import InsightTextComponent from 'components/InsightTextComponent';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { appTheme } from 'theme';
import { insightTypeToIcon } from 'utils/insights-utils';

export default function InsightItemComponent({ text, type, onOpenInsight }) {
  const [isOnHover, setIsOnHover] = useState(false);
  const mediumViewport = useMediaQuery(appTheme.breakpoints.up('md'));

  const Icon = insightTypeToIcon(type);
  return (
    <Stack
      direction="row"
      className="insight-item-component"
      id={`insight-item-component-${type}`}
      onMouseEnter={() => !isOnHover && setIsOnHover(true)}
      onMouseLeave={() => setIsOnHover(false)}
      onClick={() => onOpenInsight(type)}
      sx={{
        padding: mediumViewport ? '16px' : '8px',
        display: 'flex',
        gap: mediumViewport ? '32px' : '8px',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: '0px 0px 10px 1px rgba(0, 19, 195, 0.15)',
          borderRadius: '4px'
        }
      }}>
      <Box>
        <Icon />
      </Box>
      <InsightTextComponent text={text} type={type} mode="overview" />
    </Stack>
  );
}

InsightItemComponent.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onOpenInsight: PropTypes.func,
  recordId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
