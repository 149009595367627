import { pusherBindToCARecordId } from 'actions/custom-analytics/create-new-post-in-custom-analytics-thread-async-action';
import { loadNotificationByIdAction } from 'actions/notifications/load-user-notification-by-id-action';
import { usePusher } from 'hooks/PusherProvider';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { getUserId } from 'selectors/user';
import { getHomePageChannelName } from 'utils/pusher-utils';

export default function PusherHomePageListener() {
  const pusher = usePusher();
  const userId = useOldStateSelector(getUserId);
  const [channel, setChannel] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!pusher || !userId) {
      return;
    }
    const channelName = getHomePageChannelName(userId);
    const homepageChannel = pusher.subscribe(channelName);
    setChannel(homepageChannel);

    return () => {
      pusher.unsubscribe(channelName);
    };
  }, [userId, pusher]);

  useEffect(() => {
    if (!channel) {
      return;
    }
    channel.bind('notifications', (data) => {
      const { notification_id } = data;
      dispatch(loadNotificationByIdAction(notification_id));
    });
  }, [channel, dispatch]);

  useEffect(() => {
    if (!channel) {
      return;
    }

    channel.bind('custom_analytics_record', (data) => {
      const { post_id, record_id, company_list_id } = data;
      if (record_id && company_list_id && post_id) {
        const threadKey = getThreadKey(company_list_id);

        dispatch(
          pusherBindToCARecordId({
            listId: company_list_id,
            recordId: record_id,
            threadKey,
            postId: post_id
          })
        );
      }
    });
    return () => {
      channel?.unbind('custom_analytics_record');
    };
  }, [channel, dispatch]);

  return null;
}
