export const COMPANY_INSIGHTS_PREFIX = `/company-insights`;
export const DISCOVERY_PREFIX = `/discovery`;
export const LEGACY_DISCOVERY_PREFIX = `/discovery`;
export const EXPLORE_PREFIX = '/explore';
export const PEOPLE_INSIGHTS_PATH = `/people-insights`;
export const FOLDERS_PREFIX = '/folders';
export const ROUTES_PATHS = Object.freeze({
  LOGIN_PATH: '/login',
  SIGNUP_PATH: '/signup',
  MAIN_PATH: '/',
  ART_PATH: '/art',
  FOLDER_PATH: `${FOLDERS_PREFIX}/:folderId`,
  COMPANY_INSIGHTS_PATH: COMPANY_INSIGHTS_PREFIX,
  COMPANY_INSIGHTS_RUN_PATH: `${COMPANY_INSIGHTS_PREFIX}/:runId/:tabPath`,
  CARD_PATH: `${COMPANY_INSIGHTS_PREFIX}/:runId/cards/:cardId`,
  PEOPLE_INSIGHTS_PATH: `/people-insights`,
  ADMIN_PATH: `/admin`,
  DEV_PATH: `/dev`,
  EXPLORE_PATH: EXPLORE_PREFIX,
  EXPLORE_RUN_PATH: `${EXPLORE_PREFIX}/:listId`,
  EXPLORE_VIEW_PATH: `${EXPLORE_PREFIX}/:listId/views/:viewId`,
  LEGACY_DISCOVERY_PATH: LEGACY_DISCOVERY_PREFIX,
  LEGACY_DISCOVERY_RUN_PATH: `${LEGACY_DISCOVERY_PREFIX}/:listId`,
  LEGACY_DISCOVERY_VIEW_PATH: `${LEGACY_DISCOVERY_PREFIX}/:listId/:viewId`,
  TEAM_PATH: `/teams`,
  PROFILE_PATH: `/profile`,
  ORGANIZATION_PATH: `/organization`,
  MONITORINGS_PATH: `/monitorings`
});

export const PUBLIC_SHARE_PREFIX = '/public-share';
export const PUBLIC_ROUTES_PATHS = Object.freeze({
  PUBLIC_SHARE_PATH: `${PUBLIC_SHARE_PREFIX}/:linkId`,
  PUBLIC_SHARE_TAB_PATH: `${PUBLIC_SHARE_PREFIX}/:linkId/:tabPath`
});
