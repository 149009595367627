import {
  DISCOVERY_TYPES,
  DISCOVERY_TYPES_TITLES,
  DRY_DISCOVERY_TYPE,
  LEGACY_CUSTOM_URL_DISCOVERY_TYPE
} from 'constants/discovery';
import { CSVLink } from 'react-csv';
import ReactDOM from 'react-dom';

function createDisocoveryData(title, data) {
  if (title === DRY_DISCOVERY_TYPE) {
    return null;
  }
  if (title === DISCOVERY_TYPES_TITLES.PEERS) {
    return `Generated from ${data.map((item) => item.text).join(', ')}`;
  }
  return data;
}

function sourceValueToHistory(value) {
  if (!value) {
    return null;
  }
  const { iteration, time_submitted, title, discovery_data: data, discovered } = value;
  return {
    index: iteration === 0 ? null : iteration,
    time_submitted,
    type:
      title === LEGACY_CUSTOM_URL_DISCOVERY_TYPE
        ? DISCOVERY_TYPES_TITLES[DISCOVERY_TYPES.URL]
        : title,
    total_companies: discovered,
    user_input: createDisocoveryData(title, data)
  };
}

export function downloadDiscoveryHistoryFromSourceValues(listId, values, history) {
  if (!values) {
    return [];
  }
  const historyObject = values.reduce((acc, value) => {
    if (value.record_id in acc) {
      acc[value.record_id].discovered += 1;
    } else {
      acc[value.record_id] = {
        ...value,
        discovered: 1
      };
    }
    return acc;
  }, {});
  if (history) {
    Object.values(history).forEach((item) => {
      if (!(item.record_id in historyObject) && item.iteration !== 0) {
        historyObject[item.record_id] = { ...item, discovered: 0 };
      }
    });
  }
  const historyArray = Object.values(historyObject)
    .map(sourceValueToHistory)
    .sort((item1, item2) => item1.index - item2.index);
  const element = (
    <CSVLink data={historyArray} target="_self" filename={`list_history_${listId}.csv`}>
      <div id="fake-div-inner" />
    </CSVLink>
  );

  const fakeDiv = document.createElement('div');
  fakeDiv.style = 'display:none;';
  fakeDiv.id = 'fake-div-outer';
  document.body.appendChild(fakeDiv);
  ReactDOM.render(element, document.getElementById('fake-div-outer'));
  const fakeLink = document.getElementById('fake-div-inner');
  fakeLink.click();
  document.body.removeChild(fakeDiv);
}
