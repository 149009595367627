import { Stack, Typography } from '@mui/material';
import { CUSTOM_ANALYTICS_PLACEMENT, PROMPT_PREFIX } from 'constants/custom-analytics';
import { DISCOVERY_TYPES } from 'constants/discovery';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { clearExploreTableUIState } from 'reducer/explore-table-ui';
import { getObjectLength, isEmpty } from 'utils/objects-utils';
import { extractFirstActivePromptField, getPromptFieldText } from 'utils/prompts-utils';

import CustomAnalyticsTemplatesChips from './CustomAnalyticsTemplatesChips';
import PromptErrorComponent from './PromptErrorComponent';
import PromptTextFieldComponent from './PromptTextFieldComponent';

/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
function PromptInputComponent1({
  listId,
  companyMetaId,
  handleSubmit,
  isProccessing,
  dontAllowSubmitCA,
  isDev,
  placement,
  promptConfig,
  PartnersWizard,
  setPromptConfig,
  showError,
  DomainKnowledgeSelector,
  ColumnsSelector,
  setShowError,
  featureFlags,
  domainKnowledge,
  numberOfComapnies,
  getIsInputDisabled,
  filters,
  CompaniesSelector,
  setFilters,
  ColumnSelector,
  toggleField,
  errorStatus
}) {
  const dispatch = useDispatch();
  const getPlaceholder = () => {
    if (promptConfig.useDiscover) {
      if (promptConfig.discoverMethod === DISCOVERY_TYPES.TEXT) {
        return 'Type a description that defines the companies you are looking for';
      } else if (promptConfig.discoverMethod === DISCOVERY_TYPES.PEERS) {
        return 'select companies';
      } else if (promptConfig.discoverMethod === DISCOVERY_TYPES.URL) {
        return 'Type a url';
      }
    }
    return 'Ask me anything...';
  };
  const firstActiveField = extractFirstActivePromptField(promptConfig);
  const showOnlyDiscover = numberOfComapnies <= 0;
  const handleDiscoverMethodChange = useCallback(
    (newDiscoverMethod) => {
      setShowError(false);
      if (newDiscoverMethod === DISCOVERY_TYPES.PEERS) {
        setPromptConfig((prev) => ({ ...prev, discoverMethod: newDiscoverMethod, question: '' }));
      } else {
        setPromptConfig((prev) => ({ ...prev, discoverMethod: newDiscoverMethod }));
        dispatch(clearExploreTableUIState({ listId }));
      }
    },
    [listId, dispatch, setPromptConfig, setShowError]
  );

  return (
    <Stack
      justifyContent="center"
      gap="16px"
      sx={{
        boxSizing: 'border-box',
        border: '1px solid',
        borderColor: 'greyColors.grey50',
        borderRadius: '4px',
        margin: '16px',
        padding: '16px 16px 0 16px',
        paddingTop: '4px',
        boxShadow: '0px 1px 8px 0px #19213D14'
      }}>
      <CustomAnalyticsTemplatesChips
        promptConfig={promptConfig}
        toggleField={toggleField}
        domainKnowledge={domainKnowledge}
        showOnlyDiscover={showOnlyDiscover}
        filters={filters}
        setFilters={setFilters}
        handleDiscoverMethodChange={handleDiscoverMethodChange}
        placement={placement}
        featureFlags={featureFlags}
      />
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        gap="4px"
        sx={{
          border: '1px solid',
          borderColor: showError ? 'indicator.negative' : 'greyColors.grey100',
          borderRadius: '4px',
          padding: '4px 8px',
          backgroundColor: 'white',
          boxShadow: '0px 1px 5px 0px #1018281A',
          color: 'greyColors.grey250'
        }}>
        <Stack direction="row" gap="4px" alignItems="center" flex={1}>
          {promptConfig.forEachCompany && !companyMetaId && (
            <Stack direction="row" gap="4px" alignItems="center">
              {featureFlags.includes('ca_automatically_create_column') ? (
                <Typography variant="paragraphMedium">{PROMPT_PREFIX.ADD_COLUMN.title}</Typography>
              ) : (
                <Typography variant="paragraphMedium">
                  {isEmpty(promptConfig.companies)
                    ? getPromptFieldText('forEachCompany', firstActiveField)
                    : `For ${getObjectLength(promptConfig.companies)} selected compan${
                        getObjectLength(promptConfig.companies) === 1 ? 'y' : 'ies'
                      }`}
                </Typography>
              )}
            </Stack>
          )}
          {promptConfig.useColumn && (
            <Stack direction="row" gap="8px" alignItems="center">
              <Typography variant="paragraphMedium">
                {getPromptFieldText(
                  placement === CUSTOM_ANALYTICS_PLACEMENT.EXPLORE ? 'useColumn' : 'useCard',
                  firstActiveField
                )}
              </Typography>
              {ColumnSelector}
            </Stack>
          )}
          {promptConfig.useColumns && (
            <Stack direction="row" gap="8px" alignItems="center">
              <Typography variant="paragraphMedium">
                {getPromptFieldText('useColumns', firstActiveField)}
              </Typography>
              {ColumnsSelector}
            </Stack>
          )}
          {promptConfig.useDomainKnowledge && (
            <Stack direction="row" gap="8px" alignItems="center">
              <Typography variant="paragraphMedium">
                {getPromptFieldText('useDomainKnowledge', firstActiveField)}
              </Typography>
              {DomainKnowledgeSelector}
            </Stack>
          )}
          {promptConfig.useDiscover && listId && (
            <Stack direction="row" gap="8px" alignItems="center" flex={1}>
              {promptConfig.discoverMethod === DISCOVERY_TYPES.PEERS && CompaniesSelector}
              {promptConfig.discoverMethod === DISCOVERY_TYPES.PARTNERS && PartnersWizard}
            </Stack>
          )}
        </Stack>
        <PromptTextFieldComponent
          {...{
            getPlaceholder,
            getIsInputDisabled,
            handleSubmit,
            isProccessing,
            setPromptConfig,
            promptConfig,
            isDev,
            dontAllowSubmitCA,
            setShowError
          }}
        />
      </Stack>
      <PromptErrorComponent errorStatus={errorStatus} showError={showError} />
    </Stack>
  );
}

PromptInputComponent1.propTypes = {
  listId: PropTypes.number,
  companyMetaId: PropTypes.number,
  onAddNewPost: PropTypes.func,
  placement: PropTypes.string,
  deepDiveId: PropTypes.number,
  promptConfig: PropTypes.object,
  setPromptConfig: PropTypes.func,
  showError: PropTypes.bool,
  featureFlags: PropTypes.array,
  domainKnowledge: PropTypes.array,
  numberOfComapnies: PropTypes.number,
  getIsInputDisabled: PropTypes.func,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  columnIds: PropTypes.array,
  handleSubmit: PropTypes.func,
  isProccessing: PropTypes.bool,
  dontAllowSubmitCA: PropTypes.bool,
  isDev: PropTypes.bool,
  PartnersWizard: PropTypes.element,
  DomainKnowledgeSelector: PropTypes.element,
  ColumnsSelector: PropTypes.element,
  CompaniesSelector: PropTypes.element,
  ColumnSelector: PropTypes.element,
  setShowError: PropTypes.func,
  toggleField: PropTypes.func,
  errorStatus: PropTypes.string
};

export default PromptInputComponent1;
