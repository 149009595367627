import { Button, Stack, Typography } from '@mui/material';
import { sendExploreEvent } from 'actions/users/send-user-event-action';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import DescriptionColumnFilter from 'components/elements/table/filters/columns/DescriptionColumnFilter';
import DiscoveredColumnFilter from 'components/elements/table/filters/columns/DiscoveredColumnFilter';
import FoundingRoundColumnFilter from 'components/elements/table/filters/columns/FoundingRoundColumnFilter';
import HeadquartersColumnFilter from 'components/elements/table/filters/columns/HeadquartersColumnFilter';
import { USER_EVENTS } from 'constants/userEvents';
import { dispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { clearFilterByColumnId, updateFiltersByColumnId } from 'reducer/explore-slice';
import { getExploreViewByIdAndViewId } from 'selectors/explore';

import CountryColumnFilter from './columns/CountryColumnFilte';
import CreatedAtColumnFilter from './columns/CreatedAtColumnFilter';
import CustomBoolColumnFilter from './columns/CustomBoolColumnFilter';
import CustomNumberColumnFilter from './columns/CustomNumberColumnFilter';
import FinanceColumnColumnFilter from './columns/FinanceColumnColumnFilter';
import IterationColumnFilter from './columns/IterationColumnFilter';
import NumEmployeesColumnFilter from './columns/NumEmployeesColumnFilter';
import PartnershipLevelColumnFilter from './columns/PartnershipLevelColumnFilter';
import RegionColumnFilter from './columns/RegionColumnFilter';
import TextColumnFilter from './columns/TextColumnFilter';
import NumberRangeFilterComponent from './columns/components/NumberRangeFilterComponent';

export const COLUMN_FILTER = Object.freeze({
  description: DescriptionColumnFilter,
  last_equity_round_type: FoundingRoundColumnFilter,
  last_equity_round_message: TextColumnFilter,
  region: RegionColumnFilter,
  country: CountryColumnFilter,
  hq: HeadquartersColumnFilter,
  discovered: DiscoveredColumnFilter,
  year_founded: NumberRangeFilterComponent,
  revenues: FinanceColumnColumnFilter,
  company_source: IterationColumnFilter,
  created_at: CreatedAtColumnFilter,
  num_employees: NumEmployeesColumnFilter,
  custom_str: TextColumnFilter,
  custom_number: CustomNumberColumnFilter,
  custom_analytics: TextColumnFilter,
  predefined_custom_analytics: TextColumnFilter,
  custom_boolean: CustomBoolColumnFilter,
  SAP_PARTNERSHIP_LEVEL: PartnershipLevelColumnFilter,
  AWS_PARTNERSHIP_LEVEL: PartnershipLevelColumnFilter
});

const COLUMN_FILTER_DATA_SELECTOR = Object.freeze({
  description: (data) => [data.searchTerm, data.type],
  custom_number: (data) => data?.value
});

function ScreeningFiltersItemComponent({ title, listId, id, rows, filter, viewId, data_type }) {
  const reduxDispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const view = useSelector((state) => getExploreViewByIdAndViewId(state, listId, viewId));

  const handleClear = () => {
    if (view?.filters?.[id]) {
      searchParams.append(`!${id}`, 1);
    }
    if (searchParams.has(id)) {
      searchParams.delete(id);
    }
    setSearchParams(searchParams);
    reduxDispatch(clearFilterByColumnId({ listId, columnId: id }));
  };

  const handleFilterUpdate = (data) => {
    if (data) {
      const filterDataManipulator = COLUMN_FILTER_DATA_SELECTOR[id] || ((filterData) => filterData);
      searchParams.set(id, filterDataManipulator(data));
      if (searchParams.has(`!${id}`)) {
        searchParams.delete(`!${id}`);
      }
      setSearchParams(searchParams);
      reduxDispatch(
        updateFiltersByColumnId({
          listId,
          columnId: id,
          data
        })
      );
      dispatch(
        sendExploreEvent(USER_EVENTS.FILTERED_LIST, listId, `Filtered list by ${title}`, {
          listId,
          columnId: id
        })
      );
    } else {
      handleClear();
    }
  };

  const FilterComponent = COLUMN_FILTER[id] || COLUMN_FILTER[data_type];
  if (!FilterComponent) return null;

  return (
    <Stack direction="column" gap="8px">
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap="16px">
        <Typography variant="paragraph">{title}</Typography>
        <Button onClick={handleClear}>Clear</Button>
      </Stack>
      <FilterComponent
        listId={listId}
        rows={rows}
        onUpdateFilter={handleFilterUpdate}
        existFilter={filter}
        columnId={id}
      />
    </Stack>
  );
}

ScreeningFiltersItemComponent.propTypes = {
  title: PropTypes.string,
  listId: PropTypes.number,
  id: PropTypes.string,
  rows: PropTypes.array,
  filter: PropTypes.object,
  viewId: PropTypes.string,
  data_type: PropTypes.string
};

export default wrapWithError(ScreeningFiltersItemComponent);
