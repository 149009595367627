import { OPTIONS } from 'constants/prompt2';

export function oldPromptConfigToNewPromptConfig(oldPromptConfig) {
  const newPromptConfig = {
    option: null,
    companiesOption: null,
    useColumns: oldPromptConfig.useColumns,
    useDomainKnowledge: oldPromptConfig.useDomainKnowledge
  };

  if (oldPromptConfig.useDiscover) {
    newPromptConfig.option = OPTIONS.COMPANIES;
    // Also set companiesOption
    newPromptConfig.companiesOption = oldPromptConfig.discoverMethod;
  } else if (oldPromptConfig.forEachCompany) {
    newPromptConfig.option = OPTIONS.COLUMN;
  } else {
    newPromptConfig.option = OPTIONS.ASK;
  }

  return newPromptConfig;
}
