import React from 'react';

export default function CollapseIcon() {
  return (
    <svg height="24" viewBox="0 0 24 24" fill="none">
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" />
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0979 8.16993L9.21405 7.28605L4.5 11.9999L9.21405 16.7139L10.0979 15.8301L6.26786 12L10.0979 8.16993Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4313 8.16993L17.5474 7.28605L12.8334 11.9999L17.5474 16.7139L18.4313 15.8301L14.6012 12L18.4313 8.16993Z"
        fill="currentColor"
      />
    </svg>
  );
}
