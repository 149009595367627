import { Button, Stack, Typography } from '@mui/material';
import { deleteFolderByIdAction } from 'actions/folders/delete-folder-by-id-action';
import ModalComponent from 'components/modal/ModalComponent';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getFolderNameById } from 'selectors/folders';

function DeleteFolderDialog({ open, folderId, numberOfResearches, onClose }) {
  const dispatch = useDispatch();

  const folderName = useSelector((state) => getFolderNameById(state, folderId));

  const closeHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onClose?.();
  };

  const handleDelete = (event) => {
    dispatch(deleteFolderByIdAction(folderId));
    closeHandler(event);
  };

  return (
    <ModalComponent
      title="Delete Folder"
      open={open}
      onClose={closeHandler}
      maxWidth={738}
      bottomBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: '8px' }}>
          <Button variant="outlined" onClick={closeHandler}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleDelete} disabled={numberOfResearches > 0}>
            Delete Folder
          </Button>
        </Stack>
      }>
      <Stack direction="column" gap="24px" sx={{ padding: '24px' }}>
        {numberOfResearches > 0 ? (
          <Typography variant="paragraphMedium">{`Folder "${folderName}" is not empty, there are ${numberOfResearches} researches left inside the folder, you must move those researches to other folder or delete them.`}</Typography>
        ) : (
          <Typography variant="paragraphMedium">{`Are you sure you want to delete the folder "${folderName}"?`}</Typography>
        )}
      </Stack>
    </ModalComponent>
  );
}

DeleteFolderDialog.propTypes = {
  open: PropTypes.bool,
  folderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  numberOfResearches: PropTypes.number,
  onClose: PropTypes.func
};

export default DeleteFolderDialog;
