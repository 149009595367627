import { ACTIONS } from 'constants/actions';
import { RESEARCH_TYPES } from 'constants/researches';

import {
  monitorResearchProgressAction,
  monitorResearchStatusesAction
} from './monitor-research-progress-action';

function updateResourceLoaded() {
  return { type: ACTIONS.UPDATE_RESOURCE_LOADED, resourceType: RESEARCH_TYPES.DEEP_DIVE.id };
}

export function updateRunsData(data, overwrite = true) {
  return { type: ACTIONS.ADD_RUNS_DATA, data, overwrite };
}

export function translateOrganizationForState(organization) {
  if (!organization) {
    return {};
  }
  return {
    ownerOrganizationId: organization.id,
    ownerOrganizationName: organization.name,
    ownerOrganizationLogoUrl: organization.logo_url
  };
}

export function translateRecordForState(record) {
  return {
    id: record.id,
    recordId: record.id,
    name: record.name,
    statuses: record.statuses,
    timeSubmitted: record.time_submitted,
    parameters: record.parameters,
    outputData: record.output_data,
    description: record.parameters.description,
    linkedinName: record.parameters.linkedin_name,
    linkedinUrl: record.parameters.linkedin_url,
    domain: record.parameters.company_website,
    logoUrl: record.parameters.logo_url,
    owner: record?.user?.email,
    user: record?.user,
    ownerId: record?.user?.id,
    collaboratorsUsers: record.collaborators_users,
    collaboratorsOrgs: record.collaborators_orgs,
    collaboratorsTeams: record.collaborators_teams,
    companyMetaId: record.company_meta_id,
    ...translateOrganizationForState(record?.user?.organization)
  };
}

export function loadAllRuns(parameters = { num: 50, all: false, overwrite: true }) {
  return (_state, dispatch, apiClient) => {
    apiClient.runs.fetchAllRuns(parameters).then((res) => {
      if (res.ok && res.data) {
        const { records } = res.data;
        const runsData = records.reduce((acc, dataItem) => {
          const data = { ...dataItem };
          return { ...acc, [data.id]: translateRecordForState(data) };
        }, {});
        dispatch(updateRunsData(runsData, parameters.overwrite));
        records.forEach((dataItem) => {
          try {
            const { id, statuses } = dataItem;
            const statusesSet = new Set(Object.values(statuses));
            if (
              statusesSet.has('RUNNING') ||
              statusesSet.has('RUNNABLE') ||
              statusesSet.has('STARTING')
            ) {
              dispatch(monitorResearchProgressAction(id));
              dispatch(monitorResearchStatusesAction(id));
            }
          } catch {
            /* eslint-disable no-console */
            console.log('error');
          }
        });
      }
      dispatch(updateResourceLoaded());
    });
  };
}
