import { addExploreCustomColumn, updateExploreCustomColumnData } from 'reducer/explore-slice';
import { updateCsvColumnsToUpload, updateExploreCsv } from 'reducer/explore-table-ui';
import { getCompanyMetasByListId } from 'selectors/explore';
import {
  getExploreCompaniesLoadingByListId,
  getExploreUiCsvByListId
} from 'selectors/explore-table-ui';
import { v4 as uuidv4 } from 'uuid';

export function addDiscoveryColumnsFromCsvAction(listId, selectedColumns) {
  return (dispatch, getState, apiClient) => {
    const state = getState();
    const companiesLoading = getExploreCompaniesLoadingByListId(state, listId);
    if (companiesLoading) {
      dispatch(updateCsvColumnsToUpload({ listId, columns: selectedColumns }));
      return;
    }

    const listCsv = getExploreUiCsvByListId(state, listId);
    dispatch(updateExploreCsv({ listId, csv: null }));
    const companyMeta = getCompanyMetasByListId(state, listId) || [];
    const companyNamesToMetaListId = companyMeta.reduce((result, company) => {
      result[company.parameters.name.toLowerCase()] = company.company_list_meta_id;
      return result;
    }, {});

    if (listCsv) {
      Object.keys(selectedColumns).forEach((columnIndex) => {
        const columnId = uuidv4();
        const columnSetting = {
          id: columnId,
          name: listCsv[0][columnIndex],
          type: 'custom_str'
        };
        const customColumnValues = listCsv
          .slice(1)
          .map((companyRow) => ({
            company_list_meta_id: companyNamesToMetaListId[companyRow[0].toLowerCase()],
            value: companyRow[columnIndex]
          }))
          .filter((row) => Boolean(row.company_list_meta_id));

        apiClient.companiesLists.addCustomColumn(listId, columnSetting).then((res) => {
          if (res.ok) {
            apiClient.companyListMetas
              .updateBulkOfCustomColumnValues({
                custom_column_id: columnId,
                custom_column_values: customColumnValues
              })
              .then((bulkRes) => {
                if (bulkRes.ok) {
                  customColumnValues.forEach((row) => {
                    dispatch(
                      updateExploreCustomColumnData({
                        listId,
                        companyListMetaId: parseInt(row.company_list_meta_id, 10),
                        columnId,
                        value: row.value
                      })
                    );
                  });
                }
              });
          }
        });
        dispatch(addExploreCustomColumn({ listId, columnSetting }));
      });
    }
  };
}
