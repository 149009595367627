import {
  monitorDiscoveryProgressAction,
  monitorDiscoveryStatusesAction
} from 'actions/discoveries/monitor-discovery-progress-action';
import { translateOrganizationForState } from 'actions/runs/load-all-runs-action';
import { RESEARCH_TYPES } from 'constants/researches';
import { addAllExploreLists } from 'reducer/explore-slice';
import { updateLodededResource } from 'reducer/resources-slice';

export function translateMetaRecordForState(metaRecord) {
  const translated = { ...metaRecord, customColumns: metaRecord.custom_columns };
  delete translated.custom_columns;
  return translated;
}

export function translateDiscoveryRecordForState(discoveryRecord) {
  const translated = {
    id: discoveryRecord.id,
    recordId: discoveryRecord.id,
    name: discoveryRecord.name,
    statuses: discoveryRecord.statuses,
    timeSubmitted: discoveryRecord.time_submitted,
    parameters: discoveryRecord.parameters,
    outputData: discoveryRecord.output_data,
    progress: discoveryRecord.progress,
    ready: discoveryRecord.ready,
    owner: discoveryRecord?.user?.email,
    ownerId: discoveryRecord?.user?.id,
    ...translateOrganizationForState(discoveryRecord?.user?.organization),
    companyListId: discoveryRecord?.company_list_id
  };
  return translated;
}

export function translateListRecordForState(listRecord) {
  const translated = {
    id: listRecord.id,
    name: listRecord.name,
    timeSubmitted: listRecord.time_submitted,
    companyMetas: listRecord.company_metas?.map(translateMetaRecordForState),
    customColumns: listRecord.custom_columns,
    domainKnowledge: listRecord.supporting_resources,
    discovered: listRecord.discovered,
    provided: listRecord.provided,
    total: listRecord.total,
    exampleLogos: listRecord.example_logos,
    parameters: listRecord.parameters,
    history: listRecord?.list_history?.history,
    clustersId: listRecord?.tagging_record_id,
    lastIteration: listRecord?.discovery_last_iteration,
    discoveryRecords: listRecord.records?.map(translateDiscoveryRecordForState),
    isShared: listRecord.is_shared,
    collaboratorsUsers: listRecord.collaborators_users,
    collaboratorsOrgs: listRecord.collaborators_orgs,
    collaboratorsTeams: listRecord.collaborators_teams,
    latestView: listRecord.latest_view,
    user: listRecord?.user,
    isMonitored: listRecord.is_monitored
  };
  return translated;
}

export function loadAllExploreLists(parameters = { num: 50, all: false, record_only: false }) {
  return (dispatch, _getState, apiClient) => {
    apiClient.explore.fetchAllExploreLists(parameters).then((res) => {
      if (res.ok && res.data?.explore_lists) {
        const discoveries = [];
        const exploreLists = res.data.explore_lists.reduce((acc, dataItem) => {
          acc[dataItem.id] = translateListRecordForState(dataItem);
          const listDiscoveries = acc[dataItem.id].discoveryRecords;
          discoveries.push(...listDiscoveries);
          return acc;
        }, {});
        dispatch(addAllExploreLists({ exploreLists }));
        discoveries.forEach((dataItem) => {
          try {
            const { id, statuses, companyListId } = dataItem;
            const statusesSet = new Set(Object.values(statuses));
            if (
              statusesSet.has('RUNNING') ||
              statusesSet.has('RUNNABLE') ||
              statusesSet.has('STARTING')
            ) {
              dispatch(monitorDiscoveryProgressAction(id, companyListId));
              dispatch(monitorDiscoveryStatusesAction(id));
            }
          } catch {
            /* eslint-disable no-console */
            console.log('error');
          }
        });
      }
      dispatch(updateLodededResource({ resourceType: RESEARCH_TYPES.EXPLORE.id }));
    });
  };
}
