/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */
import HistoryIcon from '@mui/icons-material/History';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { getCompanyListColumnHistoryAction } from 'actions/company_extra_data/get-company-list-column-history-action';
import { deleteDiscoveryCustomColumnNameAction } from 'actions/explore/delete-discovery-custom-column-name-action';
import { editDiscoveryCustomColumnInfoAction } from 'actions/explore/edit-discovery-custom-column-info-action';
import { editDiscoveryCustomColumnNameAction } from 'actions/explore/edit-discovery-custom-column-name-action';
import { createListFromColumnAction } from 'actions/llm_queries/check-values-contains-names-action';
import { createSchedulesByCustomAnalyticsColumnsAction } from 'actions/schedules/create-column-schedule-action';
import { deleteSchedulesByColumnsAction } from 'actions/schedules/delete-schedule-action';
import PromptDisplayComponent from 'components/discovery/PromptDisplayComponent';
import ModalComponent from 'components/modal/ModalComponent';
import { SCHEDULE_FREQUENCIES } from 'constants/schedules';
import { TOAST_TYPES } from 'constants/toasts';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import CopyIcon from 'icons/CopyIcon';
import DeleteIcon from 'icons/DeleteIcon';
import EditIcon from 'icons/EditIcon';
import MonitoringIcon from 'icons/MonitoringIcon';
import { NewListIcon } from 'icons/NewListIcon';
import OutlinedInfoIcon from 'icons/OutlinedInfoIcon';
import SearchIcon from 'icons/SearchIcon';
import VisibilityOffIcon from 'icons/VisibilityOffIcon';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { updateExploreHiddenColumns } from 'reducer/explore-slice';
import { getUserFeatureFlags } from 'selectors/user';
import { appTheme } from 'theme';
import { downloadDiscoveryHistoryFromSourceValues } from 'utils/export-utils';
import { isEmpty } from 'utils/objects-utils';

import ExtraDataColumnMenuItem from './ExtraDataColumnMenuItem';

function CustomColumnMenu({ colDef: currentColumn, ...args }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
  const [newName, setNewName] = useState('');
  const dispatch = useDispatch();
  const { field: columnId, headerName: columnName, extraParams } = currentColumn;
  const { listId, info, allowEditHeader } = extraParams;
  const featureFlags = useOldStateSelector(getUserFeatureFlags);
  const handleClose = () => setOpenDialog(false);
  const handleDeleteClose = () => setOpenDeleteDialog(false);
  const handleSettingsClose = () => setOpenSettingsDialog(false);
  const { addToast } = useAppUtils();

  const handleRename = () => {
    dispatch(editDiscoveryCustomColumnNameAction(listId, columnId, newName));
    handleClose();
  };

  const handleDelete = () => {
    dispatch(deleteDiscoveryCustomColumnNameAction(listId, columnId));
    handleClose();
  };

  const handleCopyContent = () => {
    navigator.clipboard.writeText(info?.question || info);
    handleSettingsClose();
  };

  const handleHideColumn = (event) => {
    searchParams.append('hidden_columns', columnId);
    dispatch(updateExploreHiddenColumns({ listId, columnsIds: [columnId], isHidden: true }));
    setSearchParams(searchParams);
    args.hideMenu(event);
  };

  const onError = (message) => {
    addToast(message, TOAST_TYPES.ERROR);
  };

  const handleExportHistory = () => {
    downloadDiscoveryHistoryFromSourceValues(
      listId,
      currentColumn.extraParams.values,
      currentColumn.extraParams.history
    );
  };

  const createNewListFromColumn = (event) => {
    dispatch(createListFromColumnAction(listId, currentColumn, onError));
    args.hideMenu(event);
    handleClose();
  };

  const toggleColumnMonitor = (event) => {
    if (currentColumn.type === 'custom_analytics') {
      const oldInfo = currentColumn?.extraParams?.info || {};
      const newMonitor = !oldInfo.monitor;
      const newInfo = { ...oldInfo, monitor: newMonitor };
      dispatch(editDiscoveryCustomColumnInfoAction(listId, currentColumn.field, newInfo));
      if (newMonitor) {
        dispatch(
          createSchedulesByCustomAnalyticsColumnsAction(
            listId,
            [currentColumn.field],
            SCHEDULE_FREQUENCIES.DAILY
          )
        );
      } else {
        dispatch(deleteSchedulesByColumnsAction(listId, [currentColumn.field]));
      }
    }

    args.hideMenu(event);
    handleClose();
  };

  const addLastDataColumn = () => {
    dispatch(getCompanyListColumnHistoryAction(listId, currentColumn.field));
    args.hideMenu(event);
    handleClose();
  };

  const MenuItemsDivider = <Divider sx={{ width: '95%', alignSelf: 'center' }} />;

  const menuItems = {
    info: {
      isVisible: info?.question,
      onClick: () => setOpenSettingsDialog(true),
      startIcon: <OutlinedInfoIcon />,
      text: 'Query'
    },
    rename: {
      isVisible: allowEditHeader,
      onClick: () => setOpenDialog(true),
      startIcon: <EditIcon />,
      text: 'Rename'
    },
    delete: {
      isVisible: allowEditHeader,
      onClick: () => setOpenDeleteDialog(true),
      startIcon: <DeleteIcon />,
      text: 'Delete'
    },
    hide: {
      isVisible: true,
      onClick: handleHideColumn,
      startIcon: <VisibilityOffIcon />,
      text: 'Hide Column'
    },
    newList: {
      isVisible:
        currentColumn?.type?.includes('custom_analytics') ||
        currentColumn?.type === 'custom_str' ||
        currentColumn.type === 'basic_data_column',
      onClick: createNewListFromColumn,
      startIcon: <NewListIcon />,
      text: 'Create New Company List From Column',
      tooltip: 'Will extract names and websites of companies if the column contains any.'
    },
    monitor: {
      isVisible:
        featureFlags?.includes('column_monitoring') && currentColumn?.type === 'custom_analytics',
      onClick: toggleColumnMonitor,
      startIcon: <MonitoringIcon color="currentColor" />,
      text: `${currentColumn?.extraParams?.info?.monitor ? 'Stop' : 'Start'} column monitoring`
    },
    previousValues: {
      isVisible:
        featureFlags?.includes('column_monitoring') && currentColumn?.type === 'custom_analytics',
      onClick: addLastDataColumn,
      startIcon: <SearchIcon color="currentColor" />,
      text: 'See Previous values'
    },
    exportHistory: {
      isVisible: currentColumn?.type === 'company_source',
      onClick: handleExportHistory,
      startIcon: <HistoryIcon color="currentColor" />,
      text: 'Export history'
    }
  };

  const menuGroups = Object.values({
    data: [menuItems.info],
    actions: [menuItems.rename, menuItems.delete, menuItems.hide],
    other: [menuItems.newList, menuItems.exportHistory],
    dev: [menuItems.monitor, menuItems.previousValues]
  })
    .map((groupItems) => groupItems.filter((item) => item.isVisible))
    .filter((groupItems) => !isEmpty(groupItems));

  return (
    <Stack direction="column" pagging="16px">
      <ExtraDataColumnMenuItem
        currentColumn={currentColumn}
        listId={listId}
        hideMenu={args.hideMenu}
        handleClose={handleClose}
      />

      {menuGroups.map((groupItems, index) => (
        <Stack key={index} direction="column">
          {groupItems.map(({ onClick, startIcon, text, tooltip }) => (
            <MenuItem key={text} onClick={onClick}>
              <ListItemIcon>{startIcon}</ListItemIcon>
              {tooltip ? (
                <Tooltip title={tooltip} placement="right">
                  <ListItemText>{text}</ListItemText>
                </Tooltip>
              ) : (
                <ListItemText>{text}</ListItemText>
              )}
            </MenuItem>
          ))}
          {index < menuGroups.length - 1 && MenuItemsDivider}
        </Stack>
      ))}

      <ModalComponent
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        title="Rename column name"
        maxWidth={400}
        bottomBar={
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: '100%', padding: '8px' }}>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleRename}>
              Change
            </Button>
          </Stack>
        }>
        <Stack direction="column" gap="32px" padding="24px">
          <Typography variant="paragraphMedium">Set the new column name</Typography>
          <TextField
            variant="outlined"
            placeholder="Write new column name here..."
            value={newName}
            onChange={(event) => setNewName(event.target.value)}
            sx={{
              input: {
                color: 'greyColors.grey300'
              },
              paddingLeft: '24px',
              backgroundColor: 'transparent',
              ...appTheme.typography.paragraph
            }}
          />
        </Stack>
      </ModalComponent>
      <Dialog
        open={openDeleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Delete column &quot;{columnName}&quot;</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete column &quot;{columnName}&quot;?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>cancel</Button>
          <Button onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSettingsDialog}
        onClose={handleSettingsClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Column Query</DialogTitle>
        <DialogContent>
          <PromptDisplayComponent listId={listId} prompt={info} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCopyContent}
            startIcon={<CopyIcon color="white" />}>
            Copy Content
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

CustomColumnMenu.propTypes = {
  colDef: PropTypes.object,
  onError: PropTypes.func
};

export default CustomColumnMenu;
