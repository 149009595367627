import AddIcon from '@mui/icons-material/Add';
import { Button, Stack, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { loadUserOrganizationUsersAction } from 'actions/users/load-user-organization-users-action';
import IconButtonComponent from 'components/IconButtonComponent';
import CustomAvatar from 'components/tile/CustomAvatar';
import AddViewerUserDialog from 'components/viewers/AddViewerUserDialog';
import DeleteViewerUserDialog from 'components/viewers/DeleteViewerUserDialog';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import DeleteIcon from 'icons/DeleteIcon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getUser, getUserOrganizationUsers } from 'selectors/user';

function OrganizationScreen() {
  const { state } = useAppState();
  const { addToast } = useAppUtils();
  const user = getUser(state);
  const orgUsers = getUserOrganizationUsers(state);

  const [openAddViewerUser, setOpenAddViewerUser] = useState(false);
  const [deleteUser, setDeleteUser] = useState();

  useEffect(() => {
    dispatch(loadUserOrganizationUsersAction());
  }, []);

  const orgViewerUsers = useMemo(
    () => orgUsers?.filter((orgUser) => orgUser.is_basic) || [],
    [orgUsers]
  );

  const closeAddBasicUserModal = () => {
    setOpenAddViewerUser(false);
  };

  const onActionEnd = useCallback((type, message) => addToast(message, type), [addToast]);

  return (
    <Stack
      sx={{
        padding: '32px',
        minHeight: 'calc(100vh - 50px)',
        backgroundColor: 'background.bg100'
      }}
      gap="32px"
      alignItems="center">
      <Typography variant="h1">Organization</Typography>
      <Stack
        direction="column"
        gap="48px"
        sx={{
          padding: '24px',
          boxSizing: 'border-box',
          borderRadius: '4px',
          width: '450px',
          textAlign: 'left',
          boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
          backgroundColor: 'primary.white'
        }}>
        <Stack gap="8px">
          <Typography variant="paragraphSemiBold">Organization:</Typography>
          <Stack gap="8px" direction="row" alignItems="center">
            <CustomAvatar
              name={user.organization.name}
              pictureUrl={user.organization.logo_url}
              size={24}
              org
            />
            <Typography variant="paragraph">{user.organization.name}</Typography>
          </Stack>
        </Stack>
        <Stack direction="column" gap="24px">
          <Stack direction="row" justifyContent="space-between" gap="8px">
            <Typography variant="paragraphBold">Viewer Users</Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenAddViewerUser(true)}>
              Add Viewer
            </Button>
          </Stack>
          <Stack direction="column" gap="8px">
            <Stack direction="row" justifyContent="space-between" gap="8px">
              <Typography variant="paragraphSemiBold">Email</Typography>
              <Typography variant="paragraphSemiBold">Actions</Typography>
            </Stack>
            {orgViewerUsers.map((orgUser) => (
              <Stack
                key={orgUser.id}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="8px">
                <Typography variant="paragraph">{orgUser.email}</Typography>
                <IconButtonComponent
                  tag="Delete this viewer user"
                  onClick={() => setDeleteUser(orgUser)}>
                  <DeleteIcon />
                </IconButtonComponent>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
      <AddViewerUserDialog
        open={openAddViewerUser}
        onClose={closeAddBasicUserModal}
        onAddUserEnd={onActionEnd}
      />
      <DeleteViewerUserDialog
        user={deleteUser}
        open={Boolean(deleteUser)}
        onClose={() => setDeleteUser(null)}
        onDeleteEnd={onActionEnd}
      />
    </Stack>
  );
}

export default OrganizationScreen;
