import { updateCompanyListColumnsOrder } from 'reducer/explore-slice';
import { getListColumnsOrderByListId } from 'selectors/explore';
import { ensureMetaColumnsInOrder } from 'utils/list-utils';

export function updateDiscoveryColumnsOrderAction(listId, columnsOrder) {
  return (dispatch, getState, apiClient) => {
    const previousColumnsOrder = getListColumnsOrderByListId(getState());
    const verifiedColumnsOrder = ensureMetaColumnsInOrder(columnsOrder);
    const payload = {
      columns_order: verifiedColumnsOrder
    };

    dispatch(updateCompanyListColumnsOrder({ listId, columnsOrder: verifiedColumnsOrder }));

    apiClient.companiesLists
      .updateColumnsOrder(listId, payload)
      .then((res) => {
        if (!res.ok) {
          dispatch(updateCompanyListColumnsOrder({ listId, columnsOrder: previousColumnsOrder }));
        }
      })
      .catch(() =>
        dispatch(updateCompanyListColumnsOrder({ listId, columnsOrder: previousColumnsOrder }))
      );
  };
}
